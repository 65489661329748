import { FormattedMessage } from "react-intl";
import {
  HR,
  IconToButton,
  PriceWrapper,
  StyledH3,
  StyledNonDiscountedVariants,
  StyledNumber,
  StyledTD,
} from "./styled";
import { CartItemChildrenProps } from "./types";
import { Cancel } from "../../../../assets/Icons";
import {
  DefaultTextPrice,
  DiscountPrice,
  Price,
} from "../../../../components/Price";
import { QuantityInput } from "../QuantityInput";
import {
  CartCustomItem,
  CartSimpleItem,
  CheckoutServiceErrorCodes as ErrorCodes,
  ProductType,
} from "../../../../generated/graphql";
import { FlexRow, P, Span } from "../../../../shared/globals";
import Alert from "../../../../shared/globals/UiElements/Alert";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import { subtractMoney } from "../../../../shared/utils/money";
import CartItemInfo from "./CartItemInfo";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { PriceProps } from "../../../../components/Price/Price";
import { isProductNotAvailableError } from "../../../../shared/utils/error-utils";

const CartItemDesktop = ({
  item,
  isCustomDiscountApplied,
  isAutomaticDiscountApplied,
  discountedSimpleItemsCount,
  nonDiscountedSimpleItemsCount,
  isAllItemsDiscounted,
  itemErrors,
  value,
  setValue,
  isAlertOpen,
  setIsAlertOpen,
  onQuantityChange,
  onRemoveCartItem,
}: CartItemChildrenProps) => {
  const {
    cart: { isLoading },
  } = useCartData();

  const isProductNotAvailableErrorFound =
    isProductNotAvailableError(itemErrors);

  const isItemSimple = item?.product?.type === ProductType.Simple;

  const typedSimpleItem = item as CartSimpleItem;
  const typedCustomItem = item as CartCustomItem;

  const totalPricePerSimpleItem: PriceProps["money"] = subtractMoney(
    typedSimpleItem.price!,
    typedSimpleItem.discount?.perItem
  );

  const totalPricePerCustomItem: PriceProps["money"] = subtractMoney(
    typedCustomItem.price!,
    typedCustomItem.discount?.perItem
  );
  return (
    <>
      <tr>
        <StyledTD>
          <FlexRow alignItems="start">
            <ItemImg
              imgDetails={
                typedSimpleItem?.variant?.image ||
                typedCustomItem?.product?.images[0]
              }
            />

            <CartItemInfo
              item={item}
              isCustomDiscountApplied={isCustomDiscountApplied}
              isAutomaticDiscountApplied={isAutomaticDiscountApplied}
              discountedSimpleItemsCount={discountedSimpleItemsCount}
              isAllItemsDiscounted={isAllItemsDiscounted}
            />
          </FlexRow>
          {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
            <StyledNonDiscountedVariants>
              <FlexRow>
                <StyledNumber>{nonDiscountedSimpleItemsCount}x</StyledNumber>
                <StyledH3>
                  {typedSimpleItem.variant?.selectedOptions
                    ?.map((option) => option.value.name)
                    .join(" / ")}
                </StyledH3>
              </FlexRow>
              <PriceWrapper isNotActive={isProductNotAvailableErrorFound}>
                <DefaultTextPrice money={item.price} />
              </PriceWrapper>
            </StyledNonDiscountedVariants>
          )}
        </StyledTD>
        <StyledTD>
          <PriceWrapper isNotActive={isProductNotAvailableErrorFound}>
            {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
              <DiscountPrice style={{ fontWeight: 500 }} money={item.price} />
            )}
            <DefaultTextPrice
              money={
                isItemSimple ? totalPricePerSimpleItem : totalPricePerCustomItem
              }
            />
          </PriceWrapper>
        </StyledTD>
        <StyledTD style={{ position: "relative" }}>
          <QuantityInput
            max={
              typedSimpleItem?.variant?.maxPerCart || Number.POSITIVE_INFINITY
            }
            value={item.quantity}
            onChange={(value) => {
              setValue(value);
              onQuantityChange(value);
            }}
            disabled={isProductNotAvailableErrorFound}
            loading={isLoading}
          />
          {isAlertOpen && (
            <Alert
              message={
                value >
                (typedSimpleItem?.variant?.maxPerCart ||
                  Number?.POSITIVE_INFINITY) ? (
                  <FormattedMessage defaultMessage="Quantity exceeded" />
                ) : (
                  <FormattedMessage
                    defaultMessage="Minimum quantity per order {min}. You can add up to {max} per order"
                    values={{
                      min:
                        typedSimpleItem?.variant?.minPerCart ||
                        Number.POSITIVE_INFINITY,
                      max:
                        typedSimpleItem?.variant?.maxPerCart ||
                        Number.POSITIVE_INFINITY,
                    }}
                  />
                )
              }
              style={{ position: "absolute" }}
              closeAction={() => setIsAlertOpen(false)}
            />
          )}
        </StyledTD>
        <StyledTD>
          <PriceWrapper isNotActive={isProductNotAvailableErrorFound}>
            {isLoading ? (
              <Span>
                <P fontWeight="600" fontSize="14px">
                  <FormattedMessage defaultMessage="Calculating..." />
                </P>
              </Span>
            ) : (
              <>
                {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
                  <DiscountPrice
                    style={{ fontWeight: 500 }}
                    money={item?.subtotal}
                  />
                )}
                <Price money={item.totalPrice} />
              </>
            )}
          </PriceWrapper>
        </StyledTD>
        <StyledTD>
          <IconToButton
            type="button"
            onClick={() =>
              onRemoveCartItem(
                typedSimpleItem?.variant?.id ||
                  typedCustomItem?.product?.id ||
                  ""
              )
            }
          >
            <Cancel />
          </IconToButton>
        </StyledTD>
      </tr>
      {itemErrors?.map((itemError, i) => (
        <tr key={i}>
          <td colSpan={5} style={{ paddingTop: 5 }}>
            <Alert
              type={
                itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined
              }
              message={itemError.message}
            />
          </td>
        </tr>
      ))}
      <tr>
        <td colSpan={5} style={{ paddingTop: 15 }}>
          <HR />
        </td>
      </tr>
    </>
  );
};

export default CartItemDesktop;
