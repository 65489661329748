import { FormattedMessage } from "react-intl";
import { WuiltShipmentProvider } from "../../generated/graphql";

const ARAMEX_LOGO_URL =
  "https://wuilt-assets-v2-dev.s3.amazonaws.com/clywyyc310tgp01hyf2rehxl1_aramex.png";

const BOSTA_LOGO_URL =
  "https://wuilt-assets-v2-dev.s3.amazonaws.com/clywyyb220tgn01hygv0vdt2h_bosta.png";

const MYLERZ_LOGO_URL =
  "https://wuilt-assets-v2-dev.s3.amazonaws.com/clywyybl20tgo01hy8yz9en6o_mylerz.png";

export const companiesList = [
  {
    name: WuiltShipmentProvider.Mylerz,
    label: <FormattedMessage defaultMessage="Mylerz" />,
    imageUrl: MYLERZ_LOGO_URL,
  },
  {
    name: WuiltShipmentProvider.Aramex,
    label: <FormattedMessage defaultMessage="Aramex" />,
    imageUrl: ARAMEX_LOGO_URL,
  },
  {
    name: WuiltShipmentProvider.Bosta,
    label: <FormattedMessage defaultMessage="Bosta" />,
    imageUrl: BOSTA_LOGO_URL,
  },
];
