import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DefaultTextPrice,
  DiscountPrice,
  Price,
} from "../../../../components/Price";
import { FlexRow, P, Spinner } from "../../../../shared/globals";
import { Cancel, CouponIcon } from "../../../../assets/Icons";
import {
  FullWidth,
  ProductTitle,
  ProductPrice,
  SelectedOptions,
  ItemHolder,
  ItemInfo,
  TransparentButton,
} from "./styled";
import SideCartItemQty from "./SideCartItemQty";
import { Link } from "../../../../lib/i18n";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { Badge } from "../../../../shared/globals/UiElements/Badge";
import { getLocaleInfo } from "../../../../lib/i18n/locales-data";
import { getTranslatedDiscountTitle } from "../../../../shared/utils/getTranslatedDiscountTitle";
import {
  CartCustomItem,
  CartError,
  CartFragment,
  CartItem,
  CartSimpleItem,
  CheckoutServiceErrorCodes as ErrorCodes,
  ProductType,
} from "../../../../generated/graphql";
import { useCartData } from "../../../../lib/cartData/useCartData";
import {
  FormattedError,
  generateItemRelatedErrors,
  isProductNotAvailableError,
} from "../../../../shared/utils/error-utils";
import { useStore } from "../../../../lib/storeData";
import Alert from "../../../../shared/globals/UiElements/Alert";

interface SideCartSingleItemProps {
  item: CartFragment["items"][0];
  errors?: CartError[] | null;
}

interface SideCartItemProps {
  item: CartFragment["items"][0];
  itemErrors?: FormattedError[] | null;
  isItemNotAvailable: boolean;
}

const SideCartSingleItem: React.FC<SideCartSingleItemProps> = ({
  item,
  errors,
}) => {
  const isSimpleItem = item?.product?.type === ProductType.Simple;

  const { currency: currencyCode } = useStore();

  const itemRelatedErrors = generateItemRelatedErrors(
    item as CartItem,
    currencyCode,
    errors
  );

  const isProductNotAvailableErrorFound =
    isProductNotAvailableError(itemRelatedErrors);

  if (isSimpleItem)
    return (
      <SideCartSimpleItem
        item={item}
        itemErrors={itemRelatedErrors}
        isItemNotAvailable={isProductNotAvailableErrorFound}
      />
    );
  else
    return (
      <SideCartCustomItem
        item={item}
        itemErrors={itemRelatedErrors}
        isItemNotAvailable={isProductNotAvailableErrorFound}
      />
    );
};

const SideCartSimpleItem: React.FC<SideCartItemProps> = ({
  item,
  itemErrors,
  isItemNotAvailable,
}) => {
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;

  const {
    cart: { isLoading },
    setSideCartState,
    updateSimpleItem: { updateSimpleItem },
    removeSimpleItem: { removeSimpleItem },
  } = useCartData();

  const typedSimpleItem = item as CartSimpleItem;

  const isAutomaticDiscountApplied = !!typedSimpleItem?.discounts?.length;

  const handleRemoveCartItem = async () => {
    await removeSimpleItem(item.id, typedSimpleItem?.variant?.id || "");
  };

  const handleQuantityChange = async (value: number) => {
    await updateSimpleItem({
      item: {
        itemId: item.id,
        quantity: value,
        variantId: typedSimpleItem?.variant?.id || "",
      },
    });
  };

  return (
    <ItemHolder spacing="m" data-test="cart-item" key={item.id}>
      <FullWidth>
        <FlexRow spacing="s" alignItems="start">
          <ItemImg
            imgDetails={
              typedSimpleItem?.variant?.image || item?.product?.images[0]
            }
          />

          <Flex column spacing="xxs">
            <Flex fullWidth flexWrap="wrap" spacing="xs">
              {isAutomaticDiscountApplied && (
                <Flex flexWrap="wrap" spacing="s">
                  {typedSimpleItem?.discounts?.map((discount, idx) => (
                    <Badge
                      key={idx}
                      type="white"
                      label={
                        <Flex alignItems="center" spacing="xs">
                          <CouponIcon />
                          {getTranslatedDiscountTitle({
                            defaultTitle: discount?.data?.title,
                            currentLocale: activeLocale,
                            translations: discount?.data?.translations,
                          })}
                        </Flex>
                      }
                    />
                  ))}
                </Flex>
              )}
            </Flex>
            <ItemInfo alignItems="baseline">
              <ProductTitle
                data-test="text-item-title"
                onClick={() => setSideCartState(false)}
              >
                <Link href={`/product/all/${typedSimpleItem?.product?.handle}`}>
                  {typedSimpleItem?.product?.title}
                </Link>
              </ProductTitle>
              <SelectedOptions>
                {(typedSimpleItem?.variant?.selectedOptions || [])
                  ?.map((option) => option.value?.name)
                  .join(" / ")}
              </SelectedOptions>
              {!!typedSimpleItem?.variant?.sku && (
                <P fontSize="12px" style={{ fontStyle: "italic" }}>
                  <FormattedMessage
                    defaultMessage="SKU: {sku}"
                    values={{ sku: typedSimpleItem?.variant?.sku }}
                  />
                </P>
              )}

              {isLoading ? (
                <FlexRow spacing="s" alignItems="center">
                  <P>
                    <FormattedMessage defaultMessage="Calculating..." />
                  </P>
                  <Spinner inline={true} size={14} />
                </FlexRow>
              ) : (
                <ProductPrice data-test="item-price">
                  {isAutomaticDiscountApplied && (
                    <DiscountPrice money={typedSimpleItem?.subtotal} />
                  )}
                  <Price money={typedSimpleItem?.totalPrice} />
                </ProductPrice>
              )}
            </ItemInfo>
          </Flex>
        </FlexRow>
        <TransparentButton
          data-test="button-remove-item"
          type="button"
          disabled={isLoading}
          onClick={handleRemoveCartItem}
        >
          <Cancel />
        </TransparentButton>
      </FullWidth>
      <SideCartItemQty
        isDisabled={isItemNotAvailable}
        initialQuantity={typedSimpleItem?.quantity}
        maxQuantityPerCart={typedSimpleItem?.variant?.maxPerCart}
        minQuantityPerCart={typedSimpleItem?.variant?.minPerCart}
        handleQuantityChange={handleQuantityChange}
      />
      {itemErrors?.map((itemError, i) => (
        <Alert
          key={i}
          type={itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined}
          message={itemError.message}
        />
      ))}
    </ItemHolder>
  );
};
const SideCartCustomItem: React.FC<SideCartItemProps> = ({
  item,
  itemErrors,
  isItemNotAvailable,
}) => {
  const {
    cart: { isLoading },
    setSideCartState,
    updateCustomItem: { updateCustomItem },
    removeCustomItem: { removeCustomItem },
  } = useCartData();

  const typedCustomItem = item as CartCustomItem;

  const isCustomDiscountApplied =
    !!typedCustomItem?.discount?.info?.fixed?.amount ||
    !!typedCustomItem?.discount?.info?.percentage;

  const handleRemoveCartItem = async () => {
    await removeCustomItem({
      item: {
        itemId: item.id,
        productId: typedCustomItem?.product?.id || "",
      },
    });
  };

  const handleQuantityChange = async (value: number) => {
    await updateCustomItem({
      item: {
        productId: typedCustomItem?.product?.id || "",
        quantity: value,
      },
    });
  };

  return (
    <ItemHolder spacing="m" data-test="cart-item" key={item.id}>
      <FullWidth>
        <FlexRow spacing="s" alignItems="start">
          <ItemImg imgDetails={typedCustomItem?.product?.images[0]} />

          <Flex column spacing="xxs">
            <Flex fullWidth flexWrap="wrap" spacing="xs">
              {isCustomDiscountApplied && (
                <Badge
                  type="white"
                  label={
                    <Flex alignItems="center" spacing="xs">
                      <CouponIcon />
                      {typedCustomItem?.discount?.info?.percentage ? (
                        <FormattedMessage
                          defaultMessage="SAVE {percent}%"
                          values={{
                            percent:
                              typedCustomItem?.discount?.info?.percentage,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="SAVE {fixed}"
                          values={{
                            fixed: (
                              <DefaultTextPrice
                                money={typedCustomItem?.discount?.info?.fixed}
                              />
                            ),
                          }}
                        />
                      )}
                    </Flex>
                  }
                />
              )}
            </Flex>
            <ItemInfo alignItems="baseline">
              <ProductTitle
                data-test="text-item-title"
                onClick={() => setSideCartState(false)}
              >
                <Link href={`/product/all/${typedCustomItem?.product?.handle}`}>
                  {typedCustomItem?.product?.title}
                </Link>
              </ProductTitle>
              {isLoading ? (
                <FlexRow spacing="s" alignItems="center">
                  <P>
                    <FormattedMessage defaultMessage="Calculating" />
                  </P>
                  <Spinner inline={true} size={14} />
                </FlexRow>
              ) : (
                <ProductPrice data-test="item-price">
                  {isCustomDiscountApplied && (
                    <DiscountPrice money={typedCustomItem?.subtotal} />
                  )}
                  <Price money={typedCustomItem?.totalPrice} />
                </ProductPrice>
              )}
            </ItemInfo>
          </Flex>
        </FlexRow>
        <TransparentButton
          data-test="button-remove-item"
          type="button"
          disabled={isLoading}
          onClick={handleRemoveCartItem}
        >
          <Cancel />
        </TransparentButton>
      </FullWidth>
      <SideCartItemQty
        isDisabled={isItemNotAvailable}
        initialQuantity={typedCustomItem?.quantity}
        handleQuantityChange={handleQuantityChange}
      />
      {itemErrors?.map((itemError, i) => (
        <Alert
          key={i}
          type={itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined}
          message={itemError.message}
        />
      ))}
    </ItemHolder>
  );
};

export default SideCartSingleItem;
