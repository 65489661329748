import React from "react";
import Modal, { ModalProps } from "./Modal";
import { AlertTriangle } from "../../../assets/Icons";
import Flex from "./Flex";
import { FormattedMessage } from "react-intl";
import Button from "../../../templates/modern/components/Button/Button";
import useBreakPoints from "../../utils/useBreakPoints";

interface BuyNowConfirmModalProps extends ModalProps {
  productName: string;
  handleBuyNow: () => void;
  loading?: boolean;
}

const BuyNowConfirmModal = ({
  productName,
  handleBuyNow,
  loading,
  ...props
}: BuyNowConfirmModalProps) => {
  const { isTablet, isMobile } = useBreakPoints();

  return (
    <Modal
      onClose={props.onClose}
      show={props.show}
      modalWidth={isMobile ? "large" : isTablet ? "medium" : "small"}
      radius="12px"
    >
      <Modal.Body>
        <Flex column spacing="l">
          <div className="p-sm border-gray-200 border rounded-xl w-fit">
            <AlertTriangle />
          </div>
          <Flex column spacing="xs">
            <p className="m-0 font-semibold text-[18px] text-gray-900">
              <FormattedMessage defaultMessage="Cart reset" />
            </p>
            <p className="m-0 text-gray-600 text-sm">
              <FormattedMessage
                defaultMessage="You are about to delete all items in your cart and add <b>{productName}</b>"
                values={{
                  productName,
                  b: (...chunks) => <b>{chunks}</b>,
                }}
              />
            </p>
          </Flex>
          <Flex>
            <Button
              fullWidth
              type="secondary"
              onClick={props.onClose}
              borderRadius={8}
            >
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              fullWidth
              type="danger"
              onClick={handleBuyNow}
              borderRadius={8}
              isLoading={loading}
            >
              <FormattedMessage defaultMessage="Buy Now" />
            </Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default BuyNowConfirmModal;
