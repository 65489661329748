import { FormattedMessage } from "react-intl";
import {
  FlexRowWithMarginBottom,
  FullWidth,
  FullWidthCol,
  IconToButton,
  ItemHolder,
  PriceWrapper,
  StyledH3,
  StyledH4,
  StyledNonDiscountedVariants,
  StyledNumber,
} from "./styled";
import { CartItemChildrenProps } from "./types";
import { Cancel } from "../../../../assets/Icons";
import {
  DiscountPrice,
  DefaultTextPrice,
  Price,
} from "../../../../components/Price";
import { QuantityInput } from "../QuantityInput/QuantityInput";
import {
  CartCustomItem,
  CartSimpleItem,
  CheckoutServiceErrorCodes as ErrorCodes,
  ProductType,
} from "../../../../generated/graphql";
import { FlexRow, P } from "../../../../shared/globals";
import Alert from "../../../../shared/globals/UiElements/Alert";
import { subtractMoney } from "../../../../shared/utils/money";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import CartItemInfo from "./CartItemInfo";
import { useCartData } from "../../../../lib/cartData/useCartData";
import { PriceProps } from "../../../../components/Price/Price";
import { isProductNotAvailableError } from "../../../../shared/utils/error-utils";

const CartItemMobile = ({
  item,
  isCustomDiscountApplied,
  isAutomaticDiscountApplied,
  discountedSimpleItemsCount,
  nonDiscountedSimpleItemsCount,
  isAllItemsDiscounted,
  itemErrors,
  value,
  setValue,
  isAlertOpen,
  setIsAlertOpen,
  onQuantityChange,
  onRemoveCartItem,
}: CartItemChildrenProps) => {
  const {
    cart: { isLoading },
  } = useCartData();

  const isProductNotAvailableErrorFound =
    isProductNotAvailableError(itemErrors);

  const isItemSimple = item?.product?.type === ProductType.Simple;
  const typedSimpleItem = item as CartSimpleItem;
  const typedCustomItem = item as CartCustomItem;

  const totalPricePerSimpleItem: PriceProps["money"] = subtractMoney(
    typedSimpleItem.price!,
    typedSimpleItem.discount?.perItem
  );

  const totalPricePerCustomItem: PriceProps["money"] = subtractMoney(
    typedCustomItem.price!,
    typedCustomItem.discount?.perItem
  );

  return (
    <ItemHolder>
      <FullWidth>
        <FlexRow alignItems="flex-start">
          <ItemImg
            imgDetails={
              typedSimpleItem?.variant?.image ||
              typedCustomItem?.product?.images[0]
            }
          />

          <CartItemInfo
            item={item}
            isCustomDiscountApplied={isCustomDiscountApplied}
            isAutomaticDiscountApplied={isAutomaticDiscountApplied}
            discountedSimpleItemsCount={discountedSimpleItemsCount}
            isAllItemsDiscounted={isAllItemsDiscounted}
          />
        </FlexRow>
        <IconToButton
          type="button"
          disabled={isLoading}
          onClick={() =>
            onRemoveCartItem(
              typedSimpleItem?.variant?.id ||
                typedCustomItem?.product?.id ||
                item.id
            )
          }
        >
          <Cancel />
        </IconToButton>
      </FullWidth>
      <FullWidthCol alignItems="flex-start">
        <FlexRowWithMarginBottom alignItems="center">
          <StyledH4>
            <FormattedMessage defaultMessage="Price" />
          </StyledH4>
          <PriceWrapper isNotActive={isProductNotAvailableErrorFound}>
            {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
              <DiscountPrice style={{ fontWeight: 500 }} money={item.price} />
            )}
            <DefaultTextPrice
              money={
                isItemSimple ? totalPricePerSimpleItem : totalPricePerCustomItem
              }
            />
          </PriceWrapper>
        </FlexRowWithMarginBottom>
        {isAutomaticDiscountApplied && !isAllItemsDiscounted && (
          <StyledNonDiscountedVariants>
            <FlexRow>
              <StyledNumber>{nonDiscountedSimpleItemsCount}x</StyledNumber>
              <StyledH3>
                {(typedSimpleItem?.variant?.selectedOptions || [])
                  ?.map((option) => option.value.name)
                  .join(" / ")}
              </StyledH3>
            </FlexRow>
            <PriceWrapper
              isNotActive={isProductNotAvailableErrorFound}
              style={{ padding: "0 6px" }}
            >
              <DefaultTextPrice money={item.price} />
            </PriceWrapper>
          </StyledNonDiscountedVariants>
        )}
        <FlexRowWithMarginBottom>
          <StyledH4>
            <FormattedMessage defaultMessage="Quantity" />
          </StyledH4>
          <QuantityInput
            max={
              typedSimpleItem.variant?.maxPerCart || Number.POSITIVE_INFINITY
            }
            value={item.quantity}
            onChange={(value) => {
              setValue(value);
              onQuantityChange(value);
            }}
            disabled={isProductNotAvailableErrorFound}
            loading={isLoading}
          />
        </FlexRowWithMarginBottom>
        <FlexRowWithMarginBottom>
          <StyledH4>
            <FormattedMessage defaultMessage="Subtotal" />
          </StyledH4>
          <PriceWrapper isNotActive={isProductNotAvailableErrorFound} isBig>
            {isLoading ? (
              <P fontWeight="600">
                <FormattedMessage defaultMessage="Calculating..." />
              </P>
            ) : (
              <>
                {(isAutomaticDiscountApplied || isCustomDiscountApplied) && (
                  <DiscountPrice
                    style={{ fontWeight: 500 }}
                    money={item?.subtotal}
                  />
                )}
                <Price money={item.totalPrice} />
              </>
            )}
          </PriceWrapper>
        </FlexRowWithMarginBottom>
        {isAlertOpen && (
          <Alert
            message={
              value >
              (typedSimpleItem?.variant?.maxPerCart ||
                Number?.POSITIVE_INFINITY) ? (
                <FormattedMessage defaultMessage="Quantity exceeded" />
              ) : (
                <FormattedMessage
                  defaultMessage="Minimum quantity per order {min}. You can add up to {max} per order"
                  values={{
                    min:
                      typedSimpleItem?.variant?.minPerCart ||
                      Number.POSITIVE_INFINITY,
                    max:
                      typedSimpleItem?.variant?.maxPerCart ||
                      Number.POSITIVE_INFINITY,
                  }}
                />
              )
            }
            closeAction={() => setIsAlertOpen(false)}
          />
        )}
      </FullWidthCol>
      {itemErrors?.map((itemError, i) => (
        <Alert
          key={i}
          type={itemError.code === ErrorCodes.PriceChanged ? "warn" : undefined}
          message={itemError.message}
        />
      ))}
    </ItemHolder>
  );
};

export default CartItemMobile;
