import React from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import styled, { css } from "styled-components";
import {
  BillingAddressIcon,
  CouponIcon,
  FulfillmentStatusIcon,
  LeftSmallArrow,
  LinkShear,
  MyReviewsIcon,
  PaymentStatusIcon,
  ShippingAddressIcon,
  ShoppingBag,
  TruckIcon,
} from "../../../../assets/Icons";
import {
  CustomItem,
  DiscountAppliedOnType,
  useGetOrderByIdQuery,
  OrderInfoFragment,
  OrderPaymentStatusEnum,
  FulfillStatusEnum,
  PromoCodeTypeEnum,
} from "../../../../generated/graphql";
import { Link } from "../../../../lib/i18n";
import { useStore } from "../../../../lib/storeData";
import { FlexCol, FlexRow, H4, H5, H6 } from "../../../../shared/globals";
import { Card } from "../../../../shared/globals/UiElements/Card";
import { Badge } from "../../../../shared/globals/UiElements/Badge";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";
import { rtl, themeColor } from "../../../../shared/styles-utils";
import {
  multiplyMoneyByNumber,
  subtractMoney,
} from "../../../../shared/utils/money";
import { PrimaryButton } from "../../../../components/Button";
import {
  DefaultTextPrice,
  Price,
  DiscountPrice,
} from "../../../../components/Price";
import ReviewButton from "../../components/CustomerProfile/my-orders/ReviewButton";
import { StyledButton } from "../../components/CustomerProfile/my-orders/ReviewStyles";
import { useRouter } from "next/router";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import useBreakPoints from "../../../../shared/utils/useBreakPoints";
import { StoreTemplate } from "../../../TemplateLoader";
import { transformOrderItems } from "../../../../components/Checkout/utils/transformOrderItems";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { getTranslatedDiscountTitle } from "../../../../shared/utils/getTranslatedDiscountTitle";
import { getLocaleInfo } from "../../../../lib/i18n/locales-data";
import CartSummaryPanel from "../../../default/components/customComponents/CartSummaryPanel";

interface ItemPriceAndQtyProps {
  item?: OrderInfoFragment["items"][0] | null;
}

const ItemPriceAndQty: React.FC<ItemPriceAndQtyProps> = ({ item }) => {
  const { isTablet } = useBreakPoints();
  const isBuyXGetYDiscountApplied = !!item?.discount?.perItem?.amount!;
  const itemDiscount = item?.discount?.perItem;
  const priceBeforeDiscount = item?.price;
  const priceAfterDiscount = subtractMoney(item?.price!, itemDiscount);
  const discountedItemsCount = item?.discount?.quantity || 0;
  const nonDiscountedItemsCount = item?.quantity! - discountedItemsCount;
  const isAllItemsDiscounted = item?.quantity! === discountedItemsCount;

  return (
    <>
      {!isTablet && (
        <GridCell>
          <FlexCol alignItems="flex-start">
            {isBuyXGetYDiscountApplied && (
              <>
                <UnitDiscountedPrice money={priceBeforeDiscount} />
                <PriceCell money={priceAfterDiscount} />
              </>
            )}

            {!isAllItemsDiscounted && <PriceCell money={priceBeforeDiscount} />}
          </FlexCol>
        </GridCell>
      )}
      <GridCell>
        <FlexCol alignItems="start">
          {isTablet ? (
            <ProductCounter>
              {isBuyXGetYDiscountApplied && (
                <span>
                  <FormattedMessage defaultMessage="Qty:" />
                  {discountedItemsCount}
                </span>
              )}
              {!isAllItemsDiscounted && (
                <span>
                  <FormattedMessage defaultMessage="Qty:" />
                  {nonDiscountedItemsCount}
                </span>
              )}
            </ProductCounter>
          ) : (
            <ProductCounter>
              {isBuyXGetYDiscountApplied && (
                <span>{discountedItemsCount}X</span>
              )}
              {!isAllItemsDiscounted && <span>{nonDiscountedItemsCount}X</span>}
            </ProductCounter>
          )}
        </FlexCol>
      </GridCell>
    </>
  );
};
const OrderDetails = () => {
  const Template = StoreTemplate.get();
  const { query } = useRouter();
  const orderId = query.orderId as string;
  const { storeId, areReviewsActivated } = useStore();
  const { data, loading } = useGetOrderByIdQuery({
    variables: { storeId, orderId },
  });
  const { locale } = useIntl();
  const currentLocale = getLocaleInfo(locale).code;
  const { isTablet, isLoading } = useBreakPoints();
  const formattedOrderStatus = {
    [OrderPaymentStatusEnum.Paid]: <FormattedMessage defaultMessage="Paid" />,
    [OrderPaymentStatusEnum.Pending]: (
      <FormattedMessage defaultMessage="Pending" />
    ),
    [OrderPaymentStatusEnum.Refunded]: (
      <FormattedMessage defaultMessage="Refunded" />
    ),
    unknown: <FormattedMessage defaultMessage="Unknown" />,
  };

  const formattedFulfillmentOrderStatus = {
    [FulfillStatusEnum.Fulfilled]: (
      <FormattedMessage defaultMessage="Fulfilled" />
    ),
    [FulfillStatusEnum.PartiallyFulfilled]: (
      <FormattedMessage defaultMessage="Partially Fulfilled" />
    ),
    [FulfillStatusEnum.Unfulfilled]: (
      <FormattedMessage defaultMessage="Unfulfilled" />
    ),
    unknown: <FormattedMessage defaultMessage="Unknown" />,
  };
  const order = data?.order;

  const isShippingDiscount = !!order?.discounts?.find(
    (discount) => discount?.appliedOn === DiscountAppliedOnType.Shipping
  );

  if (loading || isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <HeaderContainer>
        <StyledLink>
          <Link href={`/me/my-orders/`}>
            <NavigateBack>
              <FlipOnRtl>
                <LeftSmallArrow />
              </FlipOnRtl>
              <H5>
                <FormattedMessage defaultMessage="Order History" />
              </H5>
            </NavigateBack>
          </Link>
        </StyledLink>
        <OrderHeaderContainer>
          <OrderMainInformation>
            <OrderSerial>
              <FormattedMessage defaultMessage="Order" /> #
              <FormattedNumber
                value={+order?.orderSerial!}
                minimumIntegerDigits={4}
                notation={+order?.orderSerial! < 1000 ? "compact" : "standard"}
              />
            </OrderSerial>
            <OrderCreatedAt>
              <FormattedDate
                value={order?.createdAt}
                day="2-digit"
                month="long"
                year="numeric"
                hour="numeric"
                minute="numeric"
              />
            </OrderCreatedAt>
          </OrderMainInformation>
          <OrderFulfillmentAndPayment>
            <FlexRow spacing={isTablet ? "s" : "l"}>
              {isTablet ? <PaymentStatusIcon /> : <>•&nbsp;</>}
              <OrderStatusLabel>
                <FormattedMessage defaultMessage="Shipping Status" />
              </OrderStatusLabel>
              <OrderStatus>
                <Flex justifyContent="start">
                  <H4 className="!text-gray-800 " fontSize="14px">
                    <FormattedMessage
                      defaultMessage="Shipped by {provider}"
                      values={{ provider: order?.wuiltShipmentProvider }}
                    />
                  </H4>
                  <Link className="!text-orange-500  flex gap-1" href={""}>
                    #475467
                    <LinkShear />
                  </Link>
                </Flex>
              </OrderStatus>
            </FlexRow>
            <FlexRow spacing={isTablet ? "s" : "l"}>
              {isTablet ? <PaymentStatusIcon /> : <>•&nbsp;</>}
              <OrderStatusLabel>
                <FormattedMessage defaultMessage="Payment Status" />
              </OrderStatusLabel>
              <OrderStatus>
                {formattedOrderStatus[order?.paymentStatus ?? "unknown"] ??
                  order?.paymentStatus}
              </OrderStatus>
            </FlexRow>
            <FlexRow spacing={isTablet ? "s" : "l"}>
              {isTablet ? <FulfillmentStatusIcon /> : <>•&nbsp;</>}
              <OrderStatusLabel>
                <FormattedMessage defaultMessage="Fulfillment Status" />
              </OrderStatusLabel>
              <OrderStatus>
                {formattedFulfillmentOrderStatus[
                  order?.fulfillmentStatus ?? "unknown"
                ] ?? order?.fulfillmentStatus}
              </OrderStatus>
            </FlexRow>
            {order?.shipmentDetails?.trackingURL && (
              <PrimaryButton
                href={order?.shipmentDetails?.trackingURL}
                target="_blank"
              >
                <TruckIcon />
                <FormattedMessage defaultMessage="Track Shipment" />
              </PrimaryButton>
            )}
          </OrderFulfillmentAndPayment>
        </OrderHeaderContainer>
      </HeaderContainer>
      <OrderDetailsContainer>
        <Card paddingSize="none" className="h-fit rounded-lg flex-1">
          <div style={{ overflowX: "auto" }}>
            <GridContainerHeader>
              <GridCell>
                <FormattedMessage defaultMessage="Product" />
              </GridCell>
              <GridCell>
                <FormattedMessage defaultMessage="Price" />
              </GridCell>
              <GridCell>
                <FormattedMessage defaultMessage="Qty." />
              </GridCell>
              <GridCell>
                <FormattedMessage defaultMessage="Total" />
              </GridCell>
            </GridContainerHeader>
            {order?.items?.map((item) => {
              if (!item) return null;
              const reviewsArrayByProductIds = order?.reviews?.map(
                (review) => review?.productId
              );
              const isItemReviewed = reviewsArrayByProductIds.includes(
                item?.productSnapshot?.id!
              );
              const customItem =
                item?.__typename === "CustomItem" ? item : null;

              return (
                <>
                  <GridContainerBodyDesktop key={item?.id}>
                    <FlexRow spacing="s">
                      <ItemImg
                        imgDetails={item?.productSnapshot?.images?.[0]}
                        size={70}
                      />
                      <ItemCell alignItems="flex-start">
                        {!!customItem?.discount?.perItem?.amount && (
                          <Badge
                            type="ink"
                            label={
                              <Flex alignItems="center" spacing="xs">
                                <CouponIcon />
                                <FormattedMessage
                                  defaultMessage="SAVE {fixed}"
                                  values={{
                                    fixed: (
                                      <DefaultTextPrice
                                        money={customItem.discount.perItem}
                                      />
                                    ),
                                  }}
                                />
                              </Flex>
                            }
                          />
                        )}

                        {!!item?.discounts?.length && (
                          <Flex flexWrap="wrap" spacing="xs">
                            {item?.discounts?.map((discount, idx) => (
                              <Badge
                                key={idx}
                                type="ink"
                                label={
                                  <Flex alignItems="center" spacing="xs">
                                    <CouponIcon />
                                    {getTranslatedDiscountTitle({
                                      defaultTitle: discount?.data?.title,
                                      translations:
                                        discount?.data?.translations,
                                      currentLocale,
                                    })}
                                  </Flex>
                                }
                              />
                            ))}
                          </Flex>
                        )}

                        <ItemTitle fontWeight="bold">{item?.title}</ItemTitle>
                        {item?.__typename === "SimpleItem" ? (
                          <>
                            <ItemVariants>
                              {item?.selectedOptions
                                ?.map((option) => option?.value)
                                .join(" - ")}
                            </ItemVariants>
                            {item?.variantSnapshot?.sku ? (
                              <H6 color="secondary">
                                <FormattedMessage
                                  defaultMessage="SKU: {sku}"
                                  values={{ sku: item?.variantSnapshot?.sku }}
                                />
                              </H6>
                            ) : null}
                          </>
                        ) : (
                          <ItemVariants>
                            <CartSummaryPanel
                              hideButtons
                              customItem={item as CustomItem}
                              customItemDiscount={item?.discount?.info}
                              isCustomDiscountApplied={
                                !!item?.discount?.info?.fixed?.amount ||
                                !!item?.discount?.info?.percentage
                              }
                            />
                          </ItemVariants>
                        )}
                      </ItemCell>
                    </FlexRow>
                    <ItemPriceAndQty item={item} />
                    <TotalPrice
                      money={subtractMoney(
                        item?.subtotal!,
                        multiplyMoneyByNumber(
                          item?.discount?.perItem!,
                          item?.discount?.quantity
                        )
                      )}
                    />
                    {item?.__typename === "SimpleItem" &&
                      areReviewsActivated && (
                        <>
                          {isItemReviewed ? (
                            <Badge
                              label={
                                <FormattedMessage defaultMessage="✔ Reviewed" />
                              }
                            />
                          ) : (
                            <ReviewButton
                              orderId={order?.id}
                              product={{
                                id: item?.productSnapshot?.id!,
                                title: item?.title!,
                                images: item?.productSnapshot?.images!,
                              }}
                              buttonComponent={
                                <StyledButton
                                  prefixIcon={<MyReviewsIcon />}
                                  outlined
                                >
                                  <FormattedMessage defaultMessage="Review" />
                                </StyledButton>
                              }
                            />
                          )}
                        </>
                      )}
                  </GridContainerBodyDesktop>
                  <GridContainerBodyMobile>
                    <ItemImg
                      imgDetails={item?.productSnapshot?.images?.[0]}
                      size={80}
                    />
                    <FlexCol spacing="s" alignItems="flex-start">
                      <ItemCell alignItems="flex-start">
                        {!!customItem?.discount?.perItem?.amount && (
                          <Badge
                            type="ink"
                            label={
                              <Flex alignItems="center" spacing="xs">
                                <CouponIcon />
                                <FormattedMessage
                                  defaultMessage="SAVE {fixed}"
                                  values={{
                                    fixed: (
                                      <DefaultTextPrice
                                        money={customItem.discount.perItem}
                                      />
                                    ),
                                  }}
                                />
                              </Flex>
                            }
                          />
                        )}

                        {!!item?.discounts?.length && (
                          <Flex flexWrap="wrap" spacing="xs">
                            {item?.discounts?.map((discount, idx) => (
                              <Badge
                                key={idx}
                                type="ink"
                                label={
                                  <Flex alignItems="center" spacing="xs">
                                    <CouponIcon />
                                    {getTranslatedDiscountTitle({
                                      defaultTitle: discount?.data?.title,
                                      translations:
                                        discount?.data?.translations,
                                      currentLocale,
                                    })}
                                  </Flex>
                                }
                              />
                            ))}
                          </Flex>
                        )}

                        <ItemTitle fontWeight="bold">{item?.title}</ItemTitle>
                        {item?.__typename === "SimpleItem" ? (
                          <>
                            <ItemVariants>
                              {item?.selectedOptions
                                ?.map((option) => option?.value)
                                .join(" - ")}
                            </ItemVariants>
                            {item?.variantSnapshot?.sku ? (
                              <H6 color="secondary">
                                <FormattedMessage
                                  defaultMessage="SKU: {sku}"
                                  values={{ sku: item?.variantSnapshot?.sku }}
                                />
                              </H6>
                            ) : null}
                          </>
                        ) : (
                          <ItemVariants>
                            <CartSummaryPanel
                              hideButtons
                              customItem={item as CustomItem}
                              customItemDiscount={item?.discount?.info}
                              isCustomDiscountApplied={
                                !!item?.discount?.info?.fixed?.amount ||
                                !!item?.discount?.info?.percentage
                              }
                            />
                          </ItemVariants>
                        )}
                      </ItemCell>
                      <ItemPriceAndQty item={item} />
                      <TotalPrice
                        money={subtractMoney(
                          item?.subtotal!,
                          multiplyMoneyByNumber(
                            item?.discount?.perItem!,
                            item?.discount?.quantity
                          )
                        )}
                      />
                      {item?.__typename === "SimpleItem" &&
                        areReviewsActivated && (
                          <>
                            {isItemReviewed ? (
                              <Badge
                                label={
                                  <FormattedMessage defaultMessage="✔ Reviewed" />
                                }
                              />
                            ) : (
                              <ReviewButton
                                orderId={order?.id}
                                product={{
                                  id: item?.productSnapshot?.id!,
                                  title: item?.title!,
                                  images: item?.productSnapshot?.images!,
                                }}
                                buttonComponent={
                                  <StyledButton
                                    prefixIcon={<MyReviewsIcon />}
                                    outlined
                                  >
                                    <FormattedMessage defaultMessage="Review" />
                                  </StyledButton>
                                }
                              />
                            )}
                          </>
                        )}
                    </FlexCol>
                  </GridContainerBodyMobile>
                </>
              );
            })}
          </div>
        </Card>

        <PaymentShippingInfo>
          <Card paddingSize="none">
            <RestInfoHeader>
              <ShoppingBagWrapper>
                <ShoppingBagCount>{order?.items.length}</ShoppingBagCount>
                <ShoppingBag />
              </ShoppingBagWrapper>
              <FormattedMessage defaultMessage="Cart Totals" />
            </RestInfoHeader>
            <RestInfoSection>
              <RestInfoRow>
                <H4 fontWeight="bold">
                  <FormattedMessage defaultMessage="Subtotal" />
                </H4>
                <PriceCell
                  money={order?.receipt?.subtotal}
                  style={{ fontSize: 14, opacity: 0.8 }}
                />
              </RestInfoRow>
            </RestInfoSection>

            <RestInfoSection>
              <H4>
                <FormattedMessage defaultMessage="Summary" />
              </H4>

              {!!order?.receipt?.automaticDiscount?.amount && (
                <Template.elements.DiscountsBreakdown
                  orderItems={transformOrderItems(order)}
                />
              )}

              {order?.receipt?.discount?.amount ? (
                <RestInfoRow>
                  <H4 fontWeight={400}>
                    {order.promoCodeSnapshot?.type ===
                    PromoCodeTypeEnum.Percentage ? (
                      <>
                        <FormattedMessage defaultMessage="Coupon" />{" "}
                        <small style={{ fontWeight: "normal" }}>
                          ({order?.promoCodeSnapshot?.percentageOff}%)
                        </small>
                      </>
                    ) : (
                      <>
                        <FormattedMessage defaultMessage="Coupon" />{" "}
                        <small style={{ fontWeight: "normal" }}>
                          ({order?.promoCodeSnapshot?.fixedAmount}%)
                        </small>
                      </>
                    )}
                  </H4>
                  <DiscountedPrice>
                    -
                    <PriceCell money={order?.receipt?.discount} />
                  </DiscountedPrice>
                </RestInfoRow>
              ) : null}

              {order?.receipt?.tax?.amount ? (
                <RestInfoRow>
                  <H4 fontWeight={400}>
                    <FormattedMessage defaultMessage="Estimated Taxes" />
                  </H4>
                  <PriceCell
                    money={order?.receipt?.tax}
                    style={{ fontSize: 14, opacity: 0.8 }}
                  />
                </RestInfoRow>
              ) : null}

              <RestInfoRow style={{ marginBottom: 0 }}>
                <H4 fontWeight={400}>
                  <FormattedMessage defaultMessage="Shipping" />{" "}
                  <small style={{ fontWeight: "normal" }}>
                    ({order?.shippingRateName})
                  </small>
                </H4>
                <ShippingNotes isShippingDiscount={isShippingDiscount}>
                  {!order?.shippingRateCost?.amount ? (
                    <FormattedMessage defaultMessage="Free Shipping" />
                  ) : (
                    <PriceCell
                      money={order?.shippingRateCost}
                      style={{ fontSize: 14 }}
                    />
                  )}
                </ShippingNotes>
              </RestInfoRow>

              {isShippingDiscount && (
                <RestInfoRow id="appliedDiscount">
                  <DiscountedPrice>
                    {!order?.receipt?.shipping?.amount ? (
                      <div>
                        <FormattedMessage defaultMessage="Free Shipping" />
                      </div>
                    ) : (
                      <PriceCell money={order?.receipt?.shipping} />
                    )}
                  </DiscountedPrice>
                </RestInfoRow>
              )}
            </RestInfoSection>
            <RestInfoSection>
              <RestInfoRow>
                <H4 fontWeight="bold">
                  <FormattedMessage defaultMessage="TOTAL" />
                </H4>
                <Price money={order?.receipt?.total} style={{ fontSize: 18 }} />
              </RestInfoRow>
            </RestInfoSection>
          </Card>
          <Card paddingSize="none">
            <RestInfoHeader>
              <ShippingAddressIcon />
              <FormattedMessage defaultMessage="Shipping address" />
            </RestInfoHeader>
            <FlexCol
              alignItems="flex-start"
              style={{
                padding: "16px",
              }}
            >
              <H4Title>{order?.customer?.name}</H4Title>
              <H4Title>{order?.shippingAddress?.addressLine1}</H4Title>
              <H4Title>{order?.shippingAddress?.addressLine2}</H4Title>
              <H4Title>{order?.shippingAddress?.postalCode}</H4Title>
              <H4Title>
                {order?.shippingAddress?.areaSnapshot?.regionName}
              </H4Title>
              <H4Title>
                {order?.shippingAddress?.areaSnapshot?.cityName}
              </H4Title>
              <H4Title>
                {order?.shippingAddress?.areaSnapshot?.stateName}
              </H4Title>
              <H4Title>
                {order?.shippingAddress?.areaSnapshot?.countryName}
              </H4Title>
              <H4Title>{order?.customer?.phone}</H4Title>
            </FlexCol>
          </Card>

          <Card paddingSize="none">
            <RestInfoHeader>
              <BillingAddressIcon />
              <FormattedMessage defaultMessage="Billing address" />
            </RestInfoHeader>
            <FlexCol
              alignItems="flex-start"
              style={{
                padding: "16px",
              }}
            >
              <H4Title>{order?.customer?.name}</H4Title>
              <H4Title>{order?.billingAddress?.addressLine1}</H4Title>
              <H4Title>{order?.billingAddress?.addressLine2}</H4Title>
              <H4Title>{order?.billingAddress.postalCode}</H4Title>
              <H4Title>
                {order?.shippingAddress?.areaSnapshot?.regionName}
              </H4Title>
              <H4Title>
                {order?.shippingAddress?.areaSnapshot?.cityName}
              </H4Title>
              <H4Title>
                {order?.shippingAddress?.areaSnapshot?.stateName}
              </H4Title>
              <H4Title>
                {order?.shippingAddress?.areaSnapshot?.countryName}
              </H4Title>
              <H4Title>{order?.customer?.phone}</H4Title>
            </FlexCol>
          </Card>
        </PaymentShippingInfo>
      </OrderDetailsContainer>
    </>
  );
};

export default OrderDetails;

const StyledLink = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }
  svg {
    margin-inline-end: 0.5rem;
  }
`;

const NavigateBack = styled(FlexRow)`
  color: ${themeColor("primary")};
  cursor: pointer;
  & > h5 {
    margin-inline-start: 5px;
    color: ${themeColor("primary")};
  }
`;
const FlipOnRtl = styled.div`
  transform: ${rtl(`rotate(180deg);`, "")};
`;

const OrderHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const OrderMainInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const OrderFulfillmentAndPayment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  @media (max-width: 768px) {
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const OrderSerial = styled(FlexRow)`
  font-weight: bold;
`;

const OrderCreatedAt = styled(H5)`
  color: ${({ theme }) => theme.text.default}80;
`;

const OrderStatusLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
`;

const OrderStatus = styled.div`
  font-size: 14px;
  opacity: 0.5;
`;

const GridContainerBodyDesktop = styled.div`
  display: grid;
  grid-template-columns: 35% 15% 15% 15% auto;
  padding: 20px;
  gap: 10px;
  border-bottom: solid 1px #dfe4e8;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const GridContainerBodyMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;

    padding: 20px;
    gap: 10px;
    :not(:last-child) {
      border-bottom: solid 1px #dfe4e8;
    }
  }
`;

const ProductCounter = styled.div`
  display: flex;
  font-weight: 600;
  @media (max-width: 768px) {
    color: ${({ theme }) => theme.text.default}80;
    font-weight: 400;
  }
`;

const GridContainerHeader = styled(GridContainerBodyDesktop)`
  & > h5 {
    color: ${({ theme }) => theme.text.default};
  }
`;

const GridCell = styled(H5)`
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
`;

const ItemCell = styled(FlexCol)`
  margin: auto 0;
`;

const ShoppingBagWrapper = styled.span`
  position: relative;
`;

const ShoppingBagCount = styled.span`
  background: ${themeColor("primary")};
  color: white;
  font-size: 8px;
  padding: 2px 4px;
  border-radius: 100%;
  position: absolute;
  right: 0px;
  top: -5px;
`;

const ItemTitle = styled(H4)`
  color: ${themeColor("primary")};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemVariants = styled(H5)`
  opacity: 0.5;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const PriceCell = styled(DefaultTextPrice)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.2px;
`;

const UnitDiscountedPrice = styled(DiscountPrice)`
  @media (min-width: 768px) {
    align-self: flex-start;
  }
`;

const TotalPrice = styled(PriceCell)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.25px;
  font-weight: bold;
  color: ${themeColor("primary")};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const RestInfoHeader = styled(FlexRow)`
  padding: 16px;
  gap: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.bg.border};
  color: ${({ theme }) => theme.text.default};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.225px;
`;

const RestInfoSection = styled(FlexCol)`
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #dfe4e8;
  align-items: flex-start;
`;

const RestInfoRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;

  &#appliedDiscount {
    justify-content: flex-end;
  }
`;

const DiscountedPrice = styled(H4)`
  color: ${({ theme }) => theme.success.medium};
  * {
    color: ${({ theme }) => theme.success.medium};
  }
`;

const ShippingNotes = styled(H4)<{ isShippingDiscount: boolean }>`
  opacity: 0.8;
  ${({ isShippingDiscount }) =>
    isShippingDiscount &&
    css`
      text-decoration: line-through;
    `}
`;

const PaymentShippingInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const H4Title = styled(H4)`
  color: ${({ theme }) => theme.text.alt};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  margin-bottom: 20px;
`;
