import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import SummaryPanel from "./SummaryPanel";
import { PrimaryButton } from "../../../../components/Button";
import {
  Money,
  CartCustomItem,
  CustomProductDiscount,
  CustomItemDiscountDetails,
  CustomProductCategoryVariant,
  GetOrderByIdQuery,
} from "../../../../generated/graphql";
import { BuildCustomState } from "../../../types";

interface CartSummaryPanelProps {
  isUpdating?: boolean;
  hideButtons?: boolean;
  isCustomDiscountApplied?: boolean;
  customItem:
    | CartCustomItem
    | NonNullable<GetOrderByIdQuery["order"]>["items"][0];
  customItemDiscount?:
    | CustomItemDiscountDetails["info"]
    | CustomProductDiscount
    | null;
}

const CartSummaryPanel: React.FC<CartSummaryPanelProps> = ({
  customItem,
  hideButtons,
  isUpdating = false,
  customItemDiscount,
  isCustomDiscountApplied,
}) => {
  const isCartCustomItem = (customItem as CartCustomItem)?.product;
  const typedCartCustomItem = customItem as CartCustomItem;
  const typedOrderCustomItem = customItem as NonNullable<
    GetOrderByIdQuery["order"]
  >["items"][0];
  const [isOpen, setIsOpen] = useState(false);
  const categories = (customItem as CartCustomItem)?.categories?.map(
    ({ category, selectedVariants }) => {
      return {
        id: category?.id,
        name: category?.name,
        img: category?.image,
        selectedVariants: selectedVariants.map(
          ({
            price,
            quantity,
            variant,
          }: {
            price?: Money;
            quantity?: number;
            variant?: CustomProductCategoryVariant["variant"];
          }) => {
            return {
              id: variant?.id,
              img: variant?.product?.images[0],
              maxQuantity: variant?.maxPerCart,
              price: price,
              title: variant?.product.title,
              quantity: quantity,
              sku: variant?.sku,
              selectedOptions: variant?.selectedOptions.map((option) => {
                return {
                  value: {
                    name: option.value.name,
                  },
                };
              }),
            };
          }
        ),
      };
    }
  );

  const buildState = {
    title: isCartCustomItem
      ? typedCartCustomItem?.product?.title
      : typedOrderCustomItem?.productSnapshot?.title,
    handle: isCartCustomItem
      ? typedCartCustomItem?.product?.handle
      : typedOrderCustomItem?.productSnapshot?.handle,
    id: isCartCustomItem
      ? typedCartCustomItem?.product?.id
      : typedOrderCustomItem?.productSnapshot?.id,
    img: isCartCustomItem
      ? typedCartCustomItem?.product?.images?.[0]
      : typedOrderCustomItem?.productSnapshot?.images?.[0],
    subtotal: customItem?.subtotal,
    total:
      customItem?.__typename === "CartCustomItem"
        ? customItem?.totalPrice
        : customItem?.price,
    categories: categories,
  };

  return (
    <>
      <PrimaryButton
        reversed
        transparent
        compact
        style={{ fontSize: 12, textDecoration: "underline" }}
        onClick={() => setIsOpen(true)}
      >
        <FormattedMessage defaultMessage="See Details" />
      </PrimaryButton>

      <SummaryPanel
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isUpdating={isUpdating}
        hideButtons={hideButtons}
        buildState={buildState as BuildCustomState}
        isCustomDiscountApplied={isCustomDiscountApplied}
        customItemDiscount={customItemDiscount}
      />
    </>
  );
};

export default CartSummaryPanel;
