import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { useInView } from "framer-motion";
import { ShoppingCart } from "../../../assets/Icons";
import { Badge } from "../../../components/Badge/Badge";
import { DiscountPrice, Price } from "../../../components/Price";
import { QuantityInput } from "../components/QuantityInput";
import { H1, P } from "../../../shared/globals";
import { rtl, themeColor } from "../../../shared/styles-utils";
import { StoreTemplate } from "../../TemplateLoader";
import type { SimpleProductProps, TemplateElement } from "../../types";
import Alert from "../../../shared/globals/UiElements/Alert";
import { useCart } from "../../../contexts/CartContext";
import { useStore } from "../../../lib/storeData";
import ProductRatingSummary from "../components/ReviewsAndRatings/ProductRatingSummary";
import { BuyNowButtonDisplay } from "../../../generated/graphql";
import AlsoLike from "../../../components/AlsoLike";
import { ProductBottomSheet } from "../components/ProductBottomSheet";
import BuyNowButton from "../components/BuyNowButton/BuyNowButton";
import { ProductBottomDetails } from "../components/ProductBottomDetails";
import Flex from "../../../shared/globals/UiElements/Flex";
import Button from "../components/Button/Button";
import { Breakpoints } from "../../../shared/globals/types";
import WhileInViewAnimation from "../../../shared/globals/WhileInViewAnimation";
import { theme } from "../../../../tailwind.config";
import { useCartData } from "../../../lib/cartData/useCartData";

const SimpleProduct: TemplateElement<SimpleProductProps> = ({
  selectedVariant,
  product,
  availableQuantity,
  isAlertOpen,
  quantity,
  handleSelect,
  setQuantity,
  handleAddToCart,
  setIsAlertOpen,
  isValueAvailableForOtherSelectedValues,
  tabs,
  isCartLoading,
  isOutOfStock,
}) => {
  const Template = StoreTemplate.get();
  const { cart } = useCart();
  const {
    cart: { isLoading },
  } = useCartData();
  const { areReviewsActivated, appearance } = useStore();
  const productDivRef = useRef<HTMLDivElement>(null);
  const isProductDivInView = useInView(productDivRef);

  const item = cart.items?.find(
    (item) => item?.variantId === selectedVariant?.id
  );

  const disableButton = (type = "") => {
    if (isOutOfStock) return true;
    if (availableQuantity?.type === "cart") {
      if (item) {
        return (
          item?.quantity! < availableQuantity?.min! &&
          selectedVariant?.trackQuantity
        );
      }
      if (type !== "quantityBtn") {
        return (
          quantity! < availableQuantity?.min! && selectedVariant?.trackQuantity
        );
      }
    }
    return false;
  };

  const isBuyNowButtonVisible =
    !!appearance?.productDisplay?.buyNowButton?.display &&
    appearance?.productDisplay?.buyNowButton?.display !==
      BuyNowButtonDisplay.None;

  return (
    <>
      <WhileInViewAnimation>
        <ProductWrapper>
          <Template.elements.ProductGallery
            selectedId={selectedVariant?.image?.id}
            images={product?.images!}
          />
          <StyledProduct id="product-head-id" ref={productDivRef}>
            <Flex column spacing="xl">
              <Flex column spacing="s">
                <StyledBadge
                  outOfStock={isOutOfStock!}
                  onSale={
                    !!selectedVariant?.compareAtPrice &&
                    selectedVariant?.price?.amount !== 0
                  }
                  className="shadow-xs"
                />
                <ProductHead>
                  <div className="mb-sm ">{product?.title}</div>
                  {areReviewsActivated && (
                    <ProductRatingSummary
                      reviewsStatistics={product?.reviewsStatistics}
                    />
                  )}
                </ProductHead>
              </Flex>
              {product?.options?.map((option, optionIndex) => (
                <div key={option?.name! + optionIndex}>
                  <Text>{option?.name}</Text>
                  <Flex flexWrap="wrap" spacing="s">
                    {option?.values?.map((value) => (
                      <OptionBox
                        key={value.id}
                        active={
                          selectedVariant?.selectedOptions?.[optionIndex]?.value
                            ?.id === value.id
                        }
                        isAvailable={
                          !!isValueAvailableForOtherSelectedValues?.(
                            selectedVariant,
                            optionIndex,
                            product,
                            value
                          )
                        }
                        as="button"
                        onClick={handleSelect?.(value, optionIndex)}
                      >
                        {value.name}
                      </OptionBox>
                    ))}
                  </Flex>
                </div>
              ))}
              {selectedVariant?.sku ? (
                <P style={{ fontStyle: "italic" }}>
                  <FormattedMessage
                    defaultMessage="SKU: {sku}"
                    values={{ sku: selectedVariant?.sku }}
                  />
                </P>
              ) : null}
              <PricingWrapper>
                {selectedVariant?.price?.amount === 0 ? (
                  <H1
                    style={{ margin: "20px 0 30px", lineHeight: "normal" }}
                    fontWeight={700}
                  >
                    <FormattedMessage defaultMessage="Free" />
                  </H1>
                ) : (
                  <Flex spacing="xs" justifyContent="flex-start">
                    {selectedVariant?.compareAtPrice && (
                      <Flex
                        spacing="none"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <DiscountPrice
                          money={selectedVariant?.compareAtPrice}
                          fontSize="24px"
                        />
                      </Flex>
                    )}
                    <Price money={selectedVariant?.price} fontSize="30px" />
                  </Flex>
                )}
              </PricingWrapper>

              {availableQuantity?.type === "cart" &&
                availableQuantity?.min! > 1 && (
                  <Alert
                    message={
                      <FormattedMessage
                        defaultMessage="Minimum quantity per order {min}. You can add up to {max} per order"
                        values={{
                          min: availableQuantity?.min,
                          max: availableQuantity?.max,
                        }}
                      />
                    }
                    style={{ marginBottom: 6 }}
                  />
                )}
              <div>
                <AddToCartWrapper isBuyNowButtonVisible={isBuyNowButtonVisible}>
                  <QuantityInput
                    max={availableQuantity?.max}
                    value={quantity!}
                    onChange={(value) => {
                      setQuantity?.(value);
                    }}
                    size="large"
                    disabled={isCartLoading || disableButton("quantityBtn")!}
                  />
                  <Button
                    prefixIcon={<ShoppingCart />}
                    fullWidth
                    onClick={() => handleAddToCart?.()}
                    isLoading={isCartLoading}
                    loadingSpinnerColor={theme.extend.colors.gray[800]}
                    isDisabled={
                      disableButton() ||
                      quantity! < (availableQuantity?.min || 1) ||
                      isLoading
                    }
                    type={isBuyNowButtonVisible ? "secondary" : "primary"}
                  >
                    <FormattedMessage defaultMessage="Add to cart" />
                  </Button>
                  {appearance?.productDisplay?.buyNowButton?.display &&
                    appearance?.productDisplay?.buyNowButton?.display !==
                      BuyNowButtonDisplay.None && (
                      <BuyNowButton
                        isDisabled={
                          disableButton() ||
                          quantity! < availableQuantity?.min! ||
                          isCartLoading ||
                          isLoading
                        }
                        product={product!}
                        quantity={quantity}
                      />
                    )}
                </AddToCartWrapper>
              </div>
              {isAlertOpen && (
                <Alert
                  message={
                    availableQuantity?.type === "stock" ? (
                      <FormattedMessage defaultMessage="Quantity exceeded" />
                    ) : (
                      <FormattedMessage
                        defaultMessage="You can only add up to {max} of this product to cart"
                        values={{ max: availableQuantity?.max }}
                      />
                    )
                  }
                  closeAction={() => setIsAlertOpen!(false)}
                />
              )}
            </Flex>
          </StyledProduct>
        </ProductWrapper>
      </WhileInViewAnimation>
      <WhileInViewAnimation amount={0.01}>
        <Template.elements.ProductDetails
          options={product?.options!}
          title={product?.title!}
          description={product?.descriptionHtml}
          attributes={product?.attributes}
          tabs={tabs!}
        />
      </WhileInViewAnimation>
      <WhileInViewAnimation>
        {areReviewsActivated && (
          <Template.elements.RatingAndReviews
            product={{
              id: product?.id!,
              title: product?.title!,
              images: product?.images!,
            }}
          />
        )}
      </WhileInViewAnimation>
      <WhileInViewAnimation>
        <AlsoLike
          products={product?.moreProducts.products!}
          isRandom={product?.moreProducts.isRandom!}
          showSeeMore
          numberOfProducts={4}
        />
      </WhileInViewAnimation>

      <ProductBottomDetails
        price={selectedVariant?.price!}
        title={product?.title!}
        options={selectedVariant?.selectedOptions!}
        image={product?.images?.[0]!}
        handleAddToCart={handleAddToCart!}
        visible={!(isProductDivInView || !productDivRef?.current)}
        disableButton={disableButton}
      />
      <ProductBottomSheet
        handleAddToCart={handleAddToCart}
        isValueAvailableForOtherSelectedValues={
          isValueAvailableForOtherSelectedValues
        }
        product={product}
        disableButton={disableButton}
        selectedVariant={selectedVariant}
        quantity={quantity}
        availableQuantity={availableQuantity}
        setQuantity={setQuantity}
        handleSelect={handleSelect}
        isCartLoading={isCartLoading}
      />
    </>
  );
};

export default SimpleProduct;

/**
 *
 *
 * Styles
 *
 */

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 24px;
  }
`;

const StyledProduct = styled.div`
  flex: 1 1 50%;
  @media (min-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const ProductHead = styled(H1)`
  font-size: 30px;
  font-weight: 500;
  line-height: 1.25;
  color: ${({ theme }) => theme.text.default};
  margin-bottom: 16px;
`;

const Text = styled(P)`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.default};
  margin-bottom: 8px;
  text-transform: capitalize;
`;

const OptionBox = styled.button<{ active: boolean; isAvailable: boolean }>`
  display: flex;
  padding: 8px 14px;
  border-radius: 50px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: solid 1px ${({ theme }) => theme.bg.inactive};
  color: ${({ theme }) => theme.text.default};
  background-color: ${({ theme }) => theme.bg.reverse};
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  text-transform: capitalize;
  cursor: pointer;

  ${({ active }) =>
    active
      ? css`
          color: ${themeColor("primary")};
          border: solid 1px ${themeColor("primary")};
          background-color: ${themeColor("white")};
          outline: none;
        `
      : ""}

  ${({ isAvailable }) =>
    !isAvailable &&
    css`
      opacity: 0.4;
    `}
`;

const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const AddToCartWrapper = styled.div<{
  isBuyNowButtonVisible: boolean;
}>`
  display: none;
  flex-direction: ${({ isBuyNowButtonVisible }) =>
    isBuyNowButtonVisible ? "column" : "row"};
  gap: 10px;
  justify-content: flex-start;
  max-width: ${({ isBuyNowButtonVisible }) =>
    isBuyNowButtonVisible ? "100%" : "430px"};

  &.dimmed {
    opacity: 0.5;
    pointer-events: none;
  }

  > * {
    :not(:last-child) {
      ${rtl(" margin:0 0 0 10px ;", " margin: 0 10px 0 0;")};
    }
  }

  @media (min-width: ${Breakpoints["TABLET"]}px) {
    display: flex;
    gap: 0px;
    flex-direction: row;
  }
`;

const StyledBadge = styled(Badge)`
  margin-bottom: 16px;
  border-radius: 999px;
  background-color: transparent;
  color: ${themeColor("primary")};
  border: 1px solid ${themeColor("primary")};
`;
