import styled from "styled-components";
import { H1, P } from "../../../../shared/globals";
import { Card } from "../../../../shared/globals/UiElements/Card";
import HTMLRender from "../../../../shared/globals/UiElements/HTMLRender";
import { rtl } from "../../../../shared/styles-utils";
import { ProductDetailsProps } from "../../../types";

export const ProductOverview: React.FC<
  Omit<ProductDetailsProps, "attributes" | "tabs">
> = ({ title, description, options }) => {
  return (
    <ItemDetails>
      <LeftBox>
        <ItemHeading>{title}</ItemHeading>
        <DetailsText html={description} />
      </LeftBox>
      <RightBox>
        {options?.map((option, i) => (
          <OptionSummary key={i} paddingSize="sm">
            <SummaryText>{option?.name}</SummaryText>
            <SummaryValues>
              {option?.values?.map((value) => value.name).join(", ")}
            </SummaryValues>
          </OptionSummary>
        ))}
      </RightBox>
    </ItemDetails>
  );
};

const ItemDetails = styled.div`
  display: flex;
  flex: 1 1 auto;
  padding: 20px;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const LeftBox = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  ${rtl("margin-left: 10px", "margin-right: 10px")};
`;

const RightBox = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex: 1 1 40%;
    padding: 0;
    width: auto;
    max-width: 30%;
  }
`;

const ItemHeading = styled(H1)`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;

  @media (min-width: 768px) {
    ${rtl("padding-left: 10px;", "padding-right: 10px;")};
  }
`;

const DetailsText = styled(HTMLRender)`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${({ theme: { text } }) => text.inkLight};
  table {
    direction: ltr;
  }
`;

const OptionSummary = styled(Card)`
  width: 100%;
  min-width: 288px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SummaryText = styled(P)`
  font-size: 14px;
  font-weight: bold;
  margin: 0 10px;
  line-height: 1.43;
  text-transform: capitalize;
`;

const SummaryValues = styled(P)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-align: right;
  color: ${({ theme: { text } }) => text.inkLight};
  text-transform: capitalize;
`;
