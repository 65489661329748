const LinkShear = ({ rotate = false }: { rotate?: boolean }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: rotate ? "rotate(180deg)" : "" }}
    >
      <path
        d="M15.8333 7.49935V4.16602H12.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8335 9.16602L15.8335 4.16602"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6668 10.834V12.5007C16.6668 14.8018 14.8013 16.6673 12.5002 16.6673H7.50016C5.19898 16.6673 3.3335 14.8018 3.3335 12.5007V7.50065C3.3335 5.19946 5.19898 3.33398 7.50016 3.33398H9.16683"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { LinkShear };
