import React from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import styled, { css } from "styled-components";
import {
  CouponIcon,
  HelpIcon,
  LeftSmallArrow,
  LinkShear,
  MyReviewsIcon,
  ReviewButtonIcon,
  TruckIcon,
} from "../../../../assets/Icons";
import {
  CustomItem,
  DiscountAppliedOnType,
  useGetOrderByIdQuery,
  OrderInfoFragment,
  OrderPaymentStatusEnum,
  FulfillStatusEnum,
  PromoCodeTypeEnum,
} from "../../../../generated/graphql";
import { Link } from "../../../../lib/i18n";
import { useStore } from "../../../../lib/storeData";
import { FlexRow, H4, H5, H6 } from "../../../../shared/globals";
import { Card } from "../../../../shared/globals/UiElements/Card";
import { Badge } from "../../../../shared/globals/UiElements/Badge";
import LoadingSpinner from "../../../../shared/globals/UiElements/LoadingSpinner";
import { rtl, themeColor } from "../../../../shared/styles-utils";
import {
  multiplyMoneyByNumber,
  subtractMoney,
} from "../../../../shared/utils/money";
import { DefaultTextPrice, DiscountPrice } from "../../../../components/Price";
import ReviewButton from "../../components/CustomerProfile/my-orders/ReviewButton";
import { StyledButton } from "../../components/CustomerProfile/my-orders/ReviewStyles";
import { useRouter } from "next/router";
import ItemImg from "../../../../shared/globals/UiElements/ItemImg";
import { StoreTemplate } from "../../../TemplateLoader";
import { transformOrderItems } from "../../../../components/Checkout/utils/transformOrderItems";
import Flex from "../../../../shared/globals/UiElements/Flex";
import { getTranslatedDiscountTitle } from "../../../../shared/utils/getTranslatedDiscountTitle";
import { getLocaleInfo } from "../../../../lib/i18n/locales-data";
import { PriceBase } from "../../../../components/Price/Price";
import useBreakPoints from "../../../../shared/utils/useBreakPoints";
import Button from "../../components/Button/Button";
import Tooltip from "../../../../shared/globals/UiElements/Tooltip";
import CartSummaryPanel from "../../components/customComponents/CartSummaryPanel";

interface ItemPriceAndQtyProps {
  item?: OrderInfoFragment["items"][0] | null;
}

const ItemPriceAndQty: React.FC<ItemPriceAndQtyProps> = ({ item }) => {
  const isBuyXGetYDiscountApplied = !!item?.discount?.perItem?.amount!;
  const itemDiscount = item?.discount?.perItem;
  const priceBeforeDiscount = item?.price;
  const priceAfterDiscount = subtractMoney(item?.price!, itemDiscount);
  const discountedItemsCount = item?.discount?.quantity || 0;
  const nonDiscountedItemsCount = item?.quantity! - discountedItemsCount;
  const isAllItemsDiscounted = item?.quantity! === discountedItemsCount;
  const { isTablet } = useBreakPoints();

  return !isTablet ? (
    <>
      <H5 fontSize="14px">
        <Flex column alignItems="start">
          {isBuyXGetYDiscountApplied && (
            <div>
              <DiscountPrice money={priceBeforeDiscount} />
              <Flex spacing="xs">
                <H5 fontSize="14px" className="text-gray-600">
                  {" "}
                  {priceAfterDiscount.currencyCode}
                </H5>
                <H5 fontSize="14px" className="text-gray-600">
                  {" "}
                  {priceAfterDiscount.amount}
                </H5>
              </Flex>
            </div>
          )}

          {!isAllItemsDiscounted && (
            <Flex spacing="xs">
              <H5 fontSize="14px" className="!text-gray-600">
                {" "}
                {priceAfterDiscount.currencyCode}
              </H5>
              <H5 fontSize="14px" className="!text-gray-600">
                {" "}
                {priceAfterDiscount.amount}
              </H5>
            </Flex>
          )}
        </Flex>
      </H5>
      <H5 fontSize="14px">
        <Flex column alignItems="start" className="text-gray-600">
          {isBuyXGetYDiscountApplied && <span> x {discountedItemsCount}</span>}
          {!isAllItemsDiscounted && <span> x {nonDiscountedItemsCount}</span>}
        </Flex>
      </H5>
    </>
  ) : (
    <Flex spacing="none">
      <H5 fontSize="14px">
        <Flex column alignItems="start">
          {isBuyXGetYDiscountApplied && (
            <div>
              <DiscountPrice className="!p-0" money={priceBeforeDiscount} />
              <Flex spacing="xs">
                <H5 fontSize="14px" className="text-gray-600">
                  {" "}
                  {priceAfterDiscount.currencyCode}
                </H5>
                <H5 fontSize="14px" className="text-gray-600">
                  {" "}
                  {priceAfterDiscount.amount}
                </H5>
              </Flex>
            </div>
          )}

          {!isAllItemsDiscounted && (
            <Flex spacing="xs">
              <H5 fontSize="14px" className="!text-gray-600">
                {" "}
                {priceAfterDiscount.currencyCode}
              </H5>
              <H5 fontSize="14px" className="!text-gray-600">
                {" "}
                {priceAfterDiscount.amount}
              </H5>
            </Flex>
          )}
        </Flex>
      </H5>
      <H5 fontSize="14px">
        <Flex column alignItems="start" className="text-gray-600">
          {isBuyXGetYDiscountApplied && <span>x{discountedItemsCount}</span>}
          {!isAllItemsDiscounted && <span>x{nonDiscountedItemsCount}</span>}
        </Flex>
      </H5>
    </Flex>
  );
};
const OrderDetails = () => {
  const Template = StoreTemplate.get();
  const { query } = useRouter();
  const orderId = query.orderId as string;
  const { storeId, areReviewsActivated } = useStore();
  const { data, loading } = useGetOrderByIdQuery({
    variables: { storeId, orderId },
  });
  const { locale } = useIntl();
  const intl = useIntl();
  const currentLocale = getLocaleInfo(locale).code;
  const { isTablet } = useBreakPoints();
  const formattedOrderStatus = {
    [OrderPaymentStatusEnum.Paid]: <FormattedMessage defaultMessage="Paid" />,
    [OrderPaymentStatusEnum.Pending]: (
      <FormattedMessage defaultMessage="Pending" />
    ),
    [OrderPaymentStatusEnum.Refunded]: (
      <FormattedMessage defaultMessage="Refunded" />
    ),
    unknown: <FormattedMessage defaultMessage="Unknown" />,
  };

  const formattedFulfillmentOrderStatus = {
    [FulfillStatusEnum.Fulfilled]: (
      <FormattedMessage defaultMessage="Fulfilled" />
    ),
    [FulfillStatusEnum.PartiallyFulfilled]: (
      <FormattedMessage defaultMessage="Partially Fulfilled" />
    ),
    [FulfillStatusEnum.Unfulfilled]: (
      <FormattedMessage defaultMessage="Unfulfilled" />
    ),
    unknown: <FormattedMessage defaultMessage="Unknown" />,
  };
  const order = data?.order;
  const isShippingDiscount = !!order?.discounts?.find(
    (discount) => discount?.appliedOn === DiscountAppliedOnType.Shipping
  );

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <HeaderContainer>
        <div>
          <StyledLink>
            <Link href={`/me/my-orders/`}>
              <NavigateBack>
                <FlipOnRtl>
                  <LeftSmallArrow />
                </FlipOnRtl>
                <H5 fontSize="14px">
                  <FormattedMessage defaultMessage="My Orders" />
                </H5>
              </NavigateBack>
            </Link>
          </StyledLink>
          <Flex spacing="s">
            <H5 fontSize="18px" fontWeight="600">
              <FormattedMessage defaultMessage="Order" /> #
              <FormattedNumber
                value={+order?.orderSerial!}
                minimumIntegerDigits={4}
                notation={+order?.orderSerial! < 1000 ? "compact" : "standard"}
              />
            </H5>
            <Badge
              className={
                order?.paymentStatus == OrderPaymentStatusEnum.Paid
                  ? "!bg-[#ECFDF3] h-fit !py-4xs !px-xs !border-success-200 !text-success-700 !rounded-full !text-xs"
                  : "!bg-warning-50 h-fit !py-4xs !px-xs !border-warning-200 !text-warning-700 !rounded-full !text-xs"
              }
              label={
                formattedOrderStatus[order?.paymentStatus ?? "unknown"] ??
                order?.paymentStatus
              }
            />
          </Flex>

          <H5 className="!text-gray-400" fontSize="12px">
            <FormattedDate
              value={order?.createdAt}
              day="2-digit"
              month="long"
              year="numeric"
              hour="numeric"
              minute="numeric"
            />
          </H5>
        </div>
        {order?.shipmentDetails?.trackingURL && (
          <Button to={order?.shipmentDetails?.trackingURL} target="_blank">
            <TruckIcon />
            <FormattedMessage defaultMessage="Track Shipment" />
          </Button>
        )}
      </HeaderContainer>
      <Card
        paddingSize="none"
        boxShadow={isTablet ? "none" : "md"}
        className="max-md:!bg-transparent max-md:!border-none"
      >
        <Flex column spacing={isTablet ? "s" : "none"}>
          <GridContainer id="Header" className="max-md:!hidden">
            <H5 fontSize="14px">
              <FormattedMessage defaultMessage="Name" />
            </H5>
            <H5 fontSize="14px">
              <FormattedMessage defaultMessage="Price" />
            </H5>
            <H5 fontSize="14px">
              <FormattedMessage defaultMessage="Quantity" />
            </H5>
            <H5 fontSize="14px">
              <FormattedMessage defaultMessage="Total" />
            </H5>
          </GridContainer>
          {order?.items?.map((item) => {
            if (!item) return null;
            const reviewsArrayByProductIds = order?.reviews?.map(
              (review) => review?.productId
            );
            const isItemReviewed = reviewsArrayByProductIds.includes(
              item?.productSnapshot?.id!
            );
            const customItem = item?.__typename === "CustomItem" ? item : null;

            return !isTablet ? (
              <GridContainer id="Body" key={item?.id}>
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing="s"
                >
                  <ItemImg imgDetails={item?.productSnapshot?.images?.[0]} />
                  <Flex column spacing="none" alignItems="flex-start">
                    {!!customItem?.discount?.perItem?.amount && (
                      <Badge
                        type="ink"
                        label={
                          <Flex alignItems="center" spacing="xs">
                            <CouponIcon />
                            <FormattedMessage
                              defaultMessage="SAVE {fixed}"
                              values={{
                                fixed: (
                                  <DefaultTextPrice
                                    money={customItem.discount.perItem}
                                  />
                                ),
                              }}
                            />
                          </Flex>
                        }
                      />
                    )}
                    <H4 fontWeight="bold" className="!text-gray-800">
                      {item?.title}
                    </H4>
                    {item?.__typename === "SimpleItem" ? (
                      <>
                        <ItemVariants>
                          {item?.selectedOptions
                            ?.map((option) => option?.value)
                            .join(" / ")}
                        </ItemVariants>
                        {item?.variantSnapshot?.sku ? (
                          <H6 color="secondary">
                            <FormattedMessage
                              defaultMessage="SKU: {sku}"
                              values={{ sku: item?.variantSnapshot?.sku }}
                            />
                          </H6>
                        ) : null}
                      </>
                    ) : (
                      <ItemVariants>
                        <CartSummaryPanel
                          hideButtons
                          customItem={item as CustomItem}
                          customItemDiscount={item?.discount?.info}
                          isCustomDiscountApplied={
                            !!item?.discount?.info?.fixed?.amount ||
                            !!item?.discount?.info?.percentage
                          }
                        />
                      </ItemVariants>
                    )}
                    {!!item?.discounts?.length && (
                      <Flex spacing="xs" flexWrap="wrap">
                        {item?.discounts?.map((discount, idx) => (
                          <Badge
                            key={idx}
                            type="ink"
                            label={
                              <Flex alignItems="center" spacing="xs">
                                <CouponIcon />
                                {getTranslatedDiscountTitle({
                                  defaultTitle: discount?.data?.title,
                                  translations: discount?.data?.translations,
                                  currentLocale,
                                })}
                              </Flex>
                            }
                          />
                        ))}
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                <ItemPriceAndQty item={item} />
                <PriceBase
                  money={subtractMoney(
                    item?.subtotal!,
                    multiplyMoneyByNumber(
                      item?.discount?.perItem!,
                      item?.discount?.quantity
                    )
                  )}
                  className="text-sm !text-gray-800"
                />
                {item?.__typename === "SimpleItem" && areReviewsActivated && (
                  <>
                    {isItemReviewed ? (
                      <Badge
                        className="!bg-[#ECFDF3] !py-3xs !px-xs !border-success-200 !text-success-700 !rounded-full !text-xs"
                        label={<FormattedMessage defaultMessage="✔ Reviewed" />}
                      />
                    ) : (
                      <ReviewButton
                        orderId={order?.id}
                        product={{
                          id: item?.productSnapshot?.id!,
                          title: item?.title!,
                          images: item?.productSnapshot?.images!,
                        }}
                        buttonComponent={
                          <StyledButton
                            type="secondary"
                            prefixIcon={<ReviewButtonIcon />}
                          >
                            <FormattedMessage defaultMessage="Review " />
                          </StyledButton>
                        }
                      />
                    )}
                  </>
                )}
              </GridContainer>
            ) : (
              <Card paddingSize="none">
                <Flex spacing="none" column>
                  <Flex
                    className="p-sm"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Flex
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing="s"
                    >
                      <ItemImg
                        imgDetails={item?.productSnapshot?.images?.[0]}
                      />
                      <Flex column spacing="none" alignItems="flex-start">
                        {!!customItem?.discount?.perItem?.amount && (
                          <Badge
                            type="ink"
                            label={
                              <Flex alignItems="center" spacing="xs">
                                <CouponIcon />
                                <FormattedMessage
                                  defaultMessage="SAVE {fixed}"
                                  values={{
                                    fixed: (
                                      <DefaultTextPrice
                                        money={customItem.discount.perItem}
                                      />
                                    ),
                                  }}
                                />
                              </Flex>
                            }
                          />
                        )}

                        <H4 fontWeight="bold" className="!text-gray-800">
                          {item?.title}
                        </H4>
                        {item?.__typename === "SimpleItem" ? (
                          <>
                            <ItemVariants>
                              {item?.selectedOptions
                                ?.map((option) => option?.value)
                                .join(" / ")}
                            </ItemVariants>
                            {item?.variantSnapshot?.sku ? (
                              <H6 color="secondary">
                                <FormattedMessage
                                  defaultMessage="SKU: {sku}"
                                  values={{ sku: item?.variantSnapshot?.sku }}
                                />
                              </H6>
                            ) : null}
                          </>
                        ) : (
                          <ItemVariants>
                            <CartSummaryPanel
                              hideButtons
                              customItem={item as CustomItem}
                              customItemDiscount={item?.discount?.info}
                              isCustomDiscountApplied={
                                !!item?.discount?.info?.fixed?.amount ||
                                !!item?.discount?.info?.percentage
                              }
                            />
                          </ItemVariants>
                        )}
                        {!!item?.discounts?.length && (
                          <Flex flexWrap="wrap" spacing="xs">
                            {item?.discounts?.map((discount, idx) => (
                              <Badge
                                key={idx}
                                type="ink"
                                label={
                                  <Flex alignItems="center" spacing="xs">
                                    <CouponIcon />
                                    {getTranslatedDiscountTitle({
                                      defaultTitle: discount?.data?.title,
                                      translations:
                                        discount?.data?.translations,
                                      currentLocale,
                                    })}
                                  </Flex>
                                }
                              />
                            ))}
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                  <div className="border-t border-gray-200 !grid !grid-cols-3 !gap-0">
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      className="p-sm border-r border-gray-200"
                    >
                      <ItemPriceAndQty item={item} />
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      className="p-sm border-r border-gray-200"
                    >
                      <div>
                        <H5 className="!text-gray-600">
                          <FormattedMessage defaultMessage="Total" />
                        </H5>
                        <PriceBase
                          money={subtractMoney(
                            item?.subtotal!,
                            multiplyMoneyByNumber(
                              item?.discount?.perItem!,
                              item?.discount?.quantity
                            )
                          )}
                          className="text-sm !text-gray-800"
                        />
                      </div>
                    </Flex>
                    {item?.__typename === "SimpleItem" &&
                      areReviewsActivated && (
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          className="p-sm"
                        >
                          {isItemReviewed ? (
                            <Badge
                              className="!bg-[#ECFDF3] !py-3xs !px-xs !border-success-200 !text-success-700 !rounded-full !text-xs"
                              label={
                                <FormattedMessage defaultMessage="✔ Reviewed" />
                              }
                            />
                          ) : (
                            <ReviewButton
                              orderId={order?.id}
                              product={{
                                id: item?.productSnapshot?.id!,
                                title: item?.title!,
                                images: item?.productSnapshot?.images!,
                              }}
                              buttonComponent={
                                <StyledButton
                                  type="secondary"
                                  prefixIcon={<MyReviewsIcon />}
                                >
                                  <FormattedMessage defaultMessage="Review" />
                                </StyledButton>
                              }
                            />
                          )}
                        </Flex>
                      )}
                  </div>
                </Flex>
              </Card>
            );
          })}
        </Flex>
      </Card>

      <Flex spacing="xxxxl" rotateOn="TABLET" className="!mt-2xl">
        <Card
          paddingSize="none"
          className="!w-2/5 max-md:!w-full max-md:!order-2 h-fit"
        >
          <Flex spacing="none" column>
            <Flex
              alignItems="flex-start"
              column
              spacing="none"
              className="!px-md !py-sm border-b border-gray-200"
            >
              <H4 className="!text-gray-500" fontSize="14px">
                <FormattedMessage defaultMessage="Fulfillment Status" />
              </H4>
              <H4 className="!text-gray-800" fontSize="14px">
                {formattedFulfillmentOrderStatus[
                  order?.fulfillmentStatus ?? "unknown"
                ] ?? order?.fulfillmentStatus}
              </H4>
            </Flex>
            <Flex
              alignItems="flex-start"
              column
              spacing="none"
              className="!px-md !py-sm border-b border-gray-200"
            >
              <H4 className="!text-gray-500" fontSize="14px">
                <FormattedMessage defaultMessage="Shipping Status" />
              </H4>
              <Flex width="100%" justifyContent="space-between">
                <H4 className="!text-gray-800 " fontSize="14px">
                  <FormattedMessage
                    defaultMessage="Shipped by {provider}"
                    values={{ provider: order?.wuiltShipmentProvider }}
                  />
                </H4>
                <Link className="!text-orange-500  flex gap-1" href={""}>
                  #475467
                  <LinkShear />
                </Link>
              </Flex>
            </Flex>
            <Flex
              alignItems="flex-start"
              column
              spacing="none"
              className="!px-md !py-sm border-b border-gray-200"
            >
              <H4 className="!text-gray-500" fontSize="14px">
                <FormattedMessage defaultMessage="Shipping address" />
              </H4>
              <H4 className="!text-gray-800" fontSize="14px">
                <span>
                  {order?.shippingAddress?.areaSnapshot?.countryName
                    ? order?.shippingAddress?.areaSnapshot?.countryName + ", "
                    : ""}
                </span>
                <span>
                  {order?.shippingAddress?.areaSnapshot?.stateName != null
                    ? order?.shippingAddress?.areaSnapshot?.stateName + ", "
                    : ", "}
                </span>
                <span>
                  {order?.shippingAddress?.areaSnapshot?.cityName != null
                    ? order?.shippingAddress?.areaSnapshot?.cityName + ", "
                    : ""}
                </span>
                <span>
                  {order?.shippingAddress?.areaSnapshot?.regionName != null
                    ? order?.shippingAddress?.areaSnapshot?.regionName + ", "
                    : ""}
                </span>
                <span>{order?.shippingAddress?.postalCode}</span>
              </H4>
            </Flex>

            <Flex
              alignItems="flex-start"
              column
              spacing="none"
              className="!px-md !py-sm"
            >
              <H4 className="!text-gray-500" fontSize="14px">
                <FormattedMessage defaultMessage="Billing address" />
              </H4>
              <H4 className="!text-gray-800" fontSize="14px">
                <span>
                  {order?.shippingAddress?.areaSnapshot?.countryName
                    ? order?.shippingAddress?.areaSnapshot?.countryName + ", "
                    : ""}
                </span>
                <span>
                  {order?.shippingAddress?.areaSnapshot?.stateName != null
                    ? order?.shippingAddress?.areaSnapshot?.stateName + ", "
                    : ", "}
                </span>
                <span>
                  {order?.shippingAddress?.areaSnapshot?.cityName != null
                    ? order?.shippingAddress?.areaSnapshot?.cityName + ", "
                    : ""}
                </span>
                <span>
                  {order?.shippingAddress?.areaSnapshot?.regionName != null
                    ? order?.shippingAddress?.areaSnapshot?.regionName + ", "
                    : ""}
                </span>
                <span>{order?.shippingAddress?.postalCode}</span>
              </H4>
            </Flex>
          </Flex>
        </Card>
        <div className="w-3/5 max-md:w-full  max-md:order-1  ">
          <Flex column>
            <Flex column className="!border-y !border-200 py-sm">
              <RestInfoRow>
                <H4 className="!text-gray-600" fontSize="14px">
                  <FormattedMessage defaultMessage="Subtotal" />
                </H4>
                <PriceCell
                  money={order?.receipt?.subtotal}
                  style={{ fontSize: 14, opacity: 0.8 }}
                />
              </RestInfoRow>

              {!!order?.receipt?.automaticDiscount?.amount && (
                <RestInfoRow id="discount">
                  <Template.elements.DiscountsBreakdown
                    orderItems={transformOrderItems(order)}
                  />
                </RestInfoRow>
              )}

              {order?.receipt?.discount?.amount ? (
                <RestInfoRow>
                  <H4 className="!text-gray-600" fontSize="14px">
                    {order.promoCodeSnapshot?.type ===
                    PromoCodeTypeEnum.Percentage ? (
                      <>
                        <FormattedMessage defaultMessage="Coupon" />{" "}
                        <small style={{ fontWeight: "normal" }}>
                          ({order?.promoCodeSnapshot?.percentageOff}%)
                        </small>
                      </>
                    ) : (
                      <>
                        <FormattedMessage defaultMessage="Coupon" />{" "}
                        <small style={{ fontWeight: "normal" }}>
                          ({order?.promoCodeSnapshot?.fixedAmount}%)
                        </small>
                      </>
                    )}
                  </H4>
                  <DiscountedPrice>
                    -
                    <PriceCell
                      money={order?.receipt?.discount}
                      style={{ fontSize: 14 }}
                    />
                  </DiscountedPrice>
                </RestInfoRow>
              ) : null}
              {order?.receipt?.tax?.amount ? (
                <RestInfoRow>
                  <Flex spacing="xs" alignItems="center">
                    <H4 className="!text-gray-600" fontSize="14px">
                      <FormattedMessage defaultMessage="Estimated Taxes" />
                    </H4>
                    <Tooltip
                      icon={<HelpIcon />}
                      tooltipMessage={intl.formatMessage({
                        defaultMessage:
                          "The final tax and total will be confirmed by email or text after you place your order.",
                      })}
                    />
                  </Flex>
                  <PriceCell
                    money={order?.receipt?.tax}
                    style={{ fontSize: 14, opacity: 0.8 }}
                  />
                </RestInfoRow>
              ) : null}
              <RestInfoRow style={{ marginBottom: 0 }}>
                <H4 className="!text-gray-600" fontSize="14px">
                  <FormattedMessage defaultMessage="Shipping" />{" "}
                  <small style={{ fontWeight: "normal" }}>
                    ({order?.shippingRateName})
                  </small>
                </H4>
                <ShippingNotes isShippingDiscount={isShippingDiscount}>
                  {!order?.shippingRateCost?.amount ? (
                    <FormattedMessage defaultMessage="Free Shipping" />
                  ) : (
                    <PriceCell
                      money={order?.shippingRateCost}
                      style={{ fontSize: 14 }}
                    />
                  )}
                </ShippingNotes>
              </RestInfoRow>
              {isShippingDiscount && (
                <RestInfoRow id="appliedDiscount">
                  <H4 />
                  <DiscountedPrice>
                    {!order?.receipt?.shipping?.amount ? (
                      <div>
                        <FormattedMessage defaultMessage="Free Shipping" />
                      </div>
                    ) : (
                      <PriceCell
                        money={order?.receipt?.shipping}
                        style={{ fontSize: 14 }}
                      />
                    )}
                  </DiscountedPrice>
                </RestInfoRow>
              )}
            </Flex>
            <RestInfoRow>
              <H4 className="!text-gray-800" fontSize="16px">
                <FormattedMessage defaultMessage="TOTAL" />
              </H4>
              <Flex column spacing="none" justifyContent="flex-end">
                <StyledPriceBase
                  className="!text-2xl font-semibold"
                  money={order?.receipt?.total}
                />
                <H4 className="text-end !text-gray-600" fontSize="14px">
                  +Shipping
                </H4>
              </Flex>
            </RestInfoRow>
          </Flex>
        </div>
      </Flex>
    </>
  );
};

export default OrderDetails;

const StyledLink = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const NavigateBack = styled(FlexRow)`
  color: ${themeColor("primary")};
  cursor: pointer;
  & > h5 {
    margin-inline-start: 5px;
    color: ${themeColor("primary")};
  }
`;
const FlipOnRtl = styled.div`
  transform: ${rtl(`rotate(180deg);`, "")};
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 15% 12% 13% 20%;
  padding: 20px;
  gap: 10px;
  border-bottom: solid 1px #dfe4e8;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 40% 20% 10% 20% auto;
  }
`;

const ItemVariants = styled(H5)`
  opacity: 0.5;
`;

const PriceCell = styled(DefaultTextPrice)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  margin: auto 0;
`;

const RestInfoRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;

  /* discount title */
  &#discount > * > h4 {
    color: ${({ theme }) => theme.text.default};
    font-weight: bold;
  }
`;

const DiscountedPrice = styled(H4)`
  opacity: 0.8;
  color: ${({ theme }) => theme.success.medium};
  * {
    color: ${({ theme }) => theme.success.medium};
  }
`;

const ShippingNotes = styled(H4)<{ isShippingDiscount: boolean }>`
  opacity: 0.8;
  ${({ isShippingDiscount }) =>
    isShippingDiscount &&
    css`
      text-decoration: line-through;
    `}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  svg {
    margin-inline-end: 0.5rem;
  }
`;
const StyledPriceBase = styled(PriceBase)`
  color: ${({ theme }) => theme.colors.primary};
`;
