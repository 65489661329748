import {
  CartFragment,
  SourceType,
  VariantInfoFragment,
} from "../../../generated/graphql";
import { createItemEvent } from "../../../lib/analytics-accounts/eventsUtilities";
import { AnalyticsActions } from "../../../lib/analytics-accounts/utils";
import { addMoney } from "../../../shared/utils/money";
import type {
  CartBuildData,
  SimpleProductProps,
} from "../../../templates/types";
import type { CartItemType, CartItemInput } from "../types";

export function fireAddToCartEvent(
  newItem: CartFragment["items"][0] | undefined | null,
  items: CartFragment["items"][0][],
  customerId: string | undefined
) {
  if (!newItem || !items.length) return;
  AnalyticsActions.fireEvent({
    name: "Add_to_Cart",
    parameters: {
      event_category: "Shop",
      event_label: newItem.product?.title ?? "",
      content_category: undefined,
      ...createItemEvent(newItem, customerId),
      items: items?.map((item) => createItemEvent(item, customerId)),
    },
  });
}

export function calculateItemsTotal(items: CartItemType[] = []) {
  return items.reduce((acc, curr) => addMoney(acc, curr?.total), {
    amount: 0,
    currencyCode: items[0]?.price?.currencyCode,
  });
}

export function addNewItem(items: CartItemType[], newItem: CartItemInput) {
  const isItemExist =
    items.findIndex((item) => item.variantId === newItem.variantId) !== -1;
  let newItems: CartItemInput[];

  if (isItemExist) {
    newItems = items.map((oldItem) =>
      oldItem.variantId === newItem.variantId
        ? { ...newItem, quantity: oldItem.quantity + newItem.quantity }
        : oldItem
    );
  } else {
    newItems = [...items, newItem];
  }
  return newItems;
}

export function getBuildSelectedItems(build: CartBuildData | undefined | null) {
  if (!build) return [];
  const selectedItems: CartItemInput[] = [];
  build.categories.forEach((category) => {
    category.selectedVariants.forEach((variant) => {
      selectedItems.push({
        variantId: variant.id,
        handle: "",
        quantity: +variant.quantity,
        availableQuantity: variant.maxQuantity
          ? { type: "stock", max: variant.maxQuantity }
          : {
              type: "infinity",
              max: Number.POSITIVE_INFINITY,
            },
        price: variant.price,
        img: variant.img,
        productId: variant.productId,
        title: variant.title,
        source: SourceType.Manual,
        sku: variant.sku,
        collectionIds: [],
        collectionNames: [],
        customDiscount: null,
        buildData: null,
        selectedOptions: variant.selectedOptions.map((option) => ({
          name: option.option.name!,
          value: option.value.name!,
        })),
      });
    });
  });

  return selectedItems;
}

export function formatAvailableQuantity(
  selectedVariant: VariantInfoFragment | undefined
): SimpleProductProps["availableQuantity"] | undefined {
  const stockQuantity = selectedVariant?.trackQuantity
    ? selectedVariant?.quantity!
    : Number.POSITIVE_INFINITY;
  const maxPerCart = selectedVariant?.maxPerCart ?? Number.POSITIVE_INFINITY;
  const minPerCart = selectedVariant?.minPerCart ?? 1;

  if (!selectedVariant?.cartLimitsEnabled && !selectedVariant?.trackQuantity) {
    return {
      type: "infinity",
      max: Number.POSITIVE_INFINITY,
    };
  }
  if (!selectedVariant?.cartLimitsEnabled || stockQuantity < minPerCart) {
    return {
      type: "stock",
      max: stockQuantity,
      min: minPerCart,
    };
  }
  if (!selectedVariant?.trackQuantity || stockQuantity >= minPerCart) {
    return {
      type: "cart",
      max: stockQuantity > maxPerCart ? maxPerCart : stockQuantity,
      min: minPerCart,
    };
  }
  return;
}

export function checkAvailableQuantities(
  items: CartItemType[],
  buildStateProp?: CartBuildData | null
) {
  let simpleItems: CartItemType[] = [];
  const buildItems: CartItemInput[] = [];

  items.forEach((item) => {
    if (item?.variantId?.startsWith?.("build")) {
      buildItems.push(item);
    } else {
      simpleItems.push(item);
    }
  });

  buildItems.forEach((buildItem) => {
    const buildSelectedItems = getBuildSelectedItems(
      buildItem?.buildData || buildStateProp
    );
    buildSelectedItems.forEach((item) => {
      simpleItems = addNewItem(simpleItems, {
        ...item,
        quantity: +item.quantity * buildItem.quantity,
      });
    });
  });

  const unAvailableItem = simpleItems.find((item) => {
    const maxQuantity =
      item?.availableQuantity?.max! ?? Number.POSITIVE_INFINITY;
    return item.quantity > maxQuantity;
  });

  return {
    isAvailable: !unAvailableItem,
    exceededItem: unAvailableItem,
  };
}

export function isAvailableToAdd(
  oldItems: CartItemType[],
  newItem: CartItemInput,
  buildData?: CartBuildData | null
) {
  const items = addNewItem(oldItems, newItem);
  return checkAvailableQuantities(items, buildData);
}
