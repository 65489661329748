import { useCallback, useContext } from "react";
import { Context } from "./context";
import {
  CartContextType,
  ShippingInformationProps,
  defaultValue,
} from "./CartContext";
import {
  updateCart as updateCartAction,
  setSideCartState as setSideCartStateAction,
  setCartLading as setCartLadingAction,
  updateRecoveryCart as updateRecoveryCartAction,
  resetRecoveryCart as resetRecoveryCartAction,
  setCartPaymentPopUpState as setCartPaymentPopUpStateAction,
  setCartEmptyPopUpState as setCartEmptyPopUpStateAction,
  setShippingInformation as setShippingInformationAction,
} from "./reducer/actionTypes";
import {
  CartCustomItem,
  UpdateCustomItemMutation,
  UpdateSimpleItemMutation,
  ApplyPromoCodeMutation,
  ApplyPromoCodeMutationVariables,
  UpdateSimpleItemMutationVariables,
  useUpdateSimpleItemMutation,
  useUpdateCustomItemMutation,
  useAddCustomItemMutation,
  AddCustomItemMutation,
  AddCustomItemMutationVariables,
  AddSimpleItemMutation,
  AddSimpleItemMutationVariables,
  UpdateContactInfoMutationVariables,
  SetCartShippingDetailsMutationVariables,
  useAddSimpleItemMutation,
  useApplyPromoCodeMutation,
  useRemovePromoCodeMutation,
  useUpdateContactInfoMutation,
  useSetCartShippingDetailsMutation,
  useSetCartShippingRateMutation,
  SetCartShippingRateMutationVariables,
  ReplaceCartItemsWithCustomItemMutationVariables,
  ReplaceCartItemsWithCustomItemMutation,
  useReplaceCartItemsWithCustomItemMutation,
  useReplaceCartItemsWithSimpleItemMutation,
  ReplaceCartItemsWithSimpleItemMutationVariables,
  ReplaceCartItemsWithSimpleItemMutation,
  useSetCartRefCodeMutation,
  SetCartRefCodeMutationVariables,
  useCheckoutCartMutation,
  CartSimpleItem,
  CheckoutServiceErrorCodes,
} from "../../generated/graphql";
import { useStore } from "../storeData";
import { parseCookies } from "nookies";
import { DefaultContext } from "@apollo/client";
import { getCartItem, getSelectedVariants } from "./utils";
import { useRouter } from "../../lib/i18n";
import { useIntl } from "react-intl";
import { fireAddToCartEvent } from "../../contexts/CartContext/reducer/utils";
import { sessionIdCheckAndGenerate } from "../../shared/utils/sessionIdCheckAndGenerate";
import { useAuth } from "../Authentication";

export function useCartData() {
  const cartContext = useContext(Context);
  const { storeId } = useStore();
  const { push, query } = useRouter();
  const router = useRouter();
  const { user } = useAuth();
  const underscoreIndex = query?.cartId ? query?.cartId?.indexOf("_") : -1;
  const cartId =
    underscoreIndex !== -1 ? query?.cartId!.slice(0, underscoreIndex + 1) : "";

  const { locale } = useIntl();
  if (!cartContext) {
    throw new Error("useCart should be in CartProvider");
  }

  const [
    addCustomItemMutation,
    { loading: addCustomItemLoading, error: addCustomItemError },
  ] = useAddCustomItemMutation();
  const [
    replaceCartWithCustomItemMutation,
    {
      loading: replaceCartWithCustomItemLoading,
      error: replaceCartWithCustomItemError,
    },
  ] = useReplaceCartItemsWithCustomItemMutation();
  const [
    addSimpleItemMutation,
    { loading: addSimpleItemLoading, error: addSimpleItemError },
  ] = useAddSimpleItemMutation();
  const [
    replaceCartWithSimpleItemMutation,
    {
      loading: replaceCartWithSimpleItemLoading,
      error: replaceCartWithSimpleItemError,
    },
  ] = useReplaceCartItemsWithSimpleItemMutation();

  const [
    updateSimpleItemMutation,
    { loading: updateSimpleItemLoading, error: updateSimpleItemError },
  ] = useUpdateSimpleItemMutation();

  const [
    updateCustomItemMutation,
    { loading: updateCustomItemLoading, error: updateCustomItemError },
  ] = useUpdateCustomItemMutation();

  const [
    applyPromoCodeMutation,
    { loading: applyPromoCodeLoading, error: applyPromoCodeError },
  ] = useApplyPromoCodeMutation();
  const [
    removePromoCodeMutation,
    { loading: removePromoCodeLoading, error: removePromoCodeError },
  ] = useRemovePromoCodeMutation();
  const [
    updateContactInfoMutation,
    { loading: UpdateContactInfoLoading, error: UpdateContactInfoError },
  ] = useUpdateContactInfoMutation();
  const [
    setCartShippingDetailsMutation,
    {
      loading: useSetCartShippingDetailsLoading,
      error: useSetCartShippingDetailsError,
    },
  ] = useSetCartShippingDetailsMutation();
  const [
    setCartShippingRateMutation,
    { loading: setCartShippingRateLoading, error: setCartShippingRateError },
  ] = useSetCartShippingRateMutation();
  const [
    setCartRefCodeMutation,
    { loading: settingCartRefCode, error: settingCartRefCodeError },
  ] = useSetCartRefCodeMutation();
  const [
    setCheckoutCartMutation,
    { loading: setCheckoutCartLoading, error: setCheckoutCartError },
  ] = useCheckoutCartMutation();
  const [cart, dispatch] = cartContext;

  const { sessionId, recoverySessionId } = parseCookies();
  const updateCart = useCallback(
    (cart: CartContextType) => {
      dispatch(updateCartAction(cart));
    },
    [dispatch]
  );

  const updateRecoveryCart = useCallback(
    (cart: CartContextType) => {
      dispatch(updateRecoveryCartAction(cart));
    },
    [dispatch]
  );

  const resetRecoveryCart = useCallback(() => {
    dispatch(resetRecoveryCartAction());
  }, [dispatch]);

  const setSideCartState = useCallback(
    (state: boolean) => {
      dispatch(setSideCartStateAction(state));
    },
    [dispatch]
  );
  const setCartPaymentPopUpState = useCallback(
    (state: boolean) => {
      dispatch(setCartPaymentPopUpStateAction(state));
    },
    [dispatch]
  );
  const setCartEmptyPopUpState = useCallback(
    (state: boolean) => {
      dispatch(setCartEmptyPopUpStateAction(state));
    },
    [dispatch]
  );
  const setShippingInformation = useCallback(
    (state: ShippingInformationProps) => {
      dispatch(setShippingInformationAction(state));
    },
    [dispatch]
  );

  const setCartLoading = useCallback(
    (state: boolean) => {
      dispatch(setCartLadingAction(state));
    },
    [dispatch]
  );

  const context: DefaultContext = {
    headers: {
      sessionId: `${recoverySessionId ?? sessionId}`,
    },
  };
  const handelErrors = (error) => {
    if (error.message === CheckoutServiceErrorCodes.NotFound) {
      updateCart(defaultValue);
      router.push("/");
    }
    if (error.message === CheckoutServiceErrorCodes.CartIsReserved)
      setCartPaymentPopUpState(true);
    if (
      error.message === CheckoutServiceErrorCodes.AddContactInfoFailed &&
      cart.isReserved == true
    )
      setCartPaymentPopUpState(true);
    if (
      error.message === CheckoutServiceErrorCodes.UpdateItemFailed &&
      cart.isReserved == true
    )
      setCartPaymentPopUpState(true);

    if (error.message == CheckoutServiceErrorCodes.NoPaymentMethods) {
      router.push(
        `/checkout/payment/${
          cart.recoveryCart != null ? query?.cartId : cart.id
        }`
      );
    }
  };
  const updateSimpleItem = async (
    variables: Omit<UpdateSimpleItemMutationVariables, "storeId">,
    onCompleted?: (data: UpdateSimpleItemMutation) => void
  ) => {
    const itemId = getCartItem(cart, variables.item.variantId)?.id;
    setCartLoading(true);
    await updateSimpleItemMutation({
      variables: {
        item: {
          quantity: variables.item.quantity,
          variantId: variables.item.variantId,
          itemId,
        },
        storeId,
      },
      context,
      onCompleted: (data) => {
        if (data?.customerActions?.cart?.updateSimpleItem) {
          updateCart(data?.customerActions?.cart?.updateSimpleItem);
          const items =
            (data.customerActions?.cart?.updateSimpleItem
              ?.items as CartSimpleItem[]) || [];
          const item = items.find(
            (item) => item.id === itemId
          ) as CartSimpleItem;
          fireAddToCartEvent(item, items!, cart.customer?.id);
        }
        if (onCompleted) onCompleted(data);
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
        setCartLoading(false);
      },
    });
    setCartLoading(false);
  };
  const updateContactInfo = async (
    variables: Omit<UpdateContactInfoMutationVariables, "storeId" | "cartId">,
    customCartId?: string
  ) => {
    await updateContactInfoMutation({
      variables: {
        ...variables,
        storeId,
        cartId: customCartId
          ? customCartId
          : cartId === "recoverycart_"
          ? "RecoveryCart_" + query.cartId?.slice(underscoreIndex + 1)
          : cart?.id,
      },
      context,
      onCompleted: (data) => {
        if (
          data?.customerActions?.cart?.updateContactInfo &&
          recoverySessionId == undefined
        ) {
          updateCart(data?.customerActions?.cart?.updateContactInfo);
        }
        if (
          data?.customerActions?.cart?.updateContactInfo &&
          recoverySessionId !== undefined
        ) {
          updateRecoveryCart(data?.customerActions?.cart?.updateContactInfo);
        }
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
  };
  const setCartShippingDetails = async (
    variables: Omit<
      SetCartShippingDetailsMutationVariables,
      "cartId" | "storeId"
    >
  ) => {
    setCartLoading(true);
    await setCartShippingDetailsMutation({
      variables: {
        ...variables,
        cartId:
          cartId === "recoverycart_"
            ? "RecoveryCart_" + query.cartId?.slice(underscoreIndex + 1)
            : cart?.id,
        storeId: storeId,
      },
      context,
      onCompleted: (data) => {
        if (
          data?.customerActions?.cart?.setCartShippingDetails &&
          recoverySessionId == undefined
        ) {
          updateCart(data?.customerActions?.cart?.setCartShippingDetails);
        }
        if (
          data?.customerActions?.cart?.setCartShippingDetails &&
          recoverySessionId !== undefined
        ) {
          updateRecoveryCart(
            data?.customerActions?.cart?.setCartShippingDetails
          );
        }
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
    setCartLoading(false);
  };

  const setCartShippingRate = async (
    variables: Omit<SetCartShippingRateMutationVariables, "cartId" | "storeId">
  ) => {
    await setCartShippingRateMutation({
      variables: {
        cartId:
          cartId === "recoverycart_"
            ? "RecoveryCart_" + query.cartId?.slice(underscoreIndex + 1)
            : cart?.id,
        ...variables,
        storeId: storeId,
      },
      context,
      onCompleted: (data) => {
        if (
          data?.customerActions?.cart?.setCartShippingRate &&
          recoverySessionId == undefined
        ) {
          updateCart(data?.customerActions?.cart?.setCartShippingRate);
        }
        if (
          data?.customerActions?.cart?.setCartShippingRate &&
          recoverySessionId !== undefined
        ) {
          updateRecoveryCart(data?.customerActions?.cart?.setCartShippingRate);
        }
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
  };

  const applyPromoCode = async (
    variables: Omit<ApplyPromoCodeMutationVariables, "cartId" | "storeId">,
    onCompleted?: (data: ApplyPromoCodeMutation) => void
  ) => {
    setCartLoading(true);
    if (cart.promoCode) await removePromoCode();

    await applyPromoCodeMutation({
      variables: {
        ...variables,
        cartId:
          cartId === "recoverycart_"
            ? "RecoveryCart_" + query.cartId?.slice(underscoreIndex + 1)
            : cart?.id,
        storeId,
      },
      context,
      onCompleted: (data) => {
        if (
          data?.customerActions?.cart?.applyPromoCode &&
          recoverySessionId == undefined
        ) {
          updateCart(data?.customerActions?.cart?.applyPromoCode);
        }
        if (
          data?.customerActions?.cart?.applyPromoCode &&
          recoverySessionId !== undefined
        ) {
          updateRecoveryCart(data?.customerActions?.cart?.applyPromoCode);
        }
        if (onCompleted) onCompleted(data);
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
    setCartLoading(false);
  };
  const removePromoCode = async (
    onCompleted?: (data: ApplyPromoCodeMutation) => void
  ) => {
    setCartLoading(true);
    await removePromoCodeMutation({
      variables: {
        cartId:
          cartId === "recoverycart_"
            ? "RecoveryCart_" + query.cartId?.slice(underscoreIndex + 1)
            : cart?.id,
        storeId,
      },
      context,
      onCompleted: (data) => {
        if (
          data?.customerActions?.cart?.removePromoCode &&
          recoverySessionId == undefined
        ) {
          updateCart(data?.customerActions?.cart?.removePromoCode);
        }
        if (
          data?.customerActions?.cart?.removePromoCode &&
          recoverySessionId !== undefined
        ) {
          updateRecoveryCart(data?.customerActions?.cart?.removePromoCode);
        }
        if (onCompleted) onCompleted(data);
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
    setCartLoading(false);
  };
  const removeSimpleItem = async (itemId: string, variantId?: string) => {
    setCartLoading(true);
    await updateSimpleItemMutation({
      variables: {
        item: {
          itemId,
          quantity: 0,
          variantId: variantId || "",
        },
        storeId,
      },
      context,
      onCompleted: (data) => {
        if (data?.customerActions?.cart?.updateSimpleItem) {
          updateCart(data?.customerActions?.cart?.updateSimpleItem);
          setCartLoading(false);
        }
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
  };

  const addSimpleItem = async (
    variables: Omit<AddSimpleItemMutationVariables, "storeId">,
    onCompleted?: (data: AddSimpleItemMutation) => void
  ) => {
    setCartLoading(true);
    const generatedSessionId = sessionIdCheckAndGenerate();
    if (generatedSessionId) {
      context.headers.sessionId = generatedSessionId;
    }

    await addSimpleItemMutation({
      variables: {
        ...variables,
        storeId,
      },
      context,
      onCompleted: async (data) => {
        const newCart = data?.customerActions?.cart?.addSimpleItem;
        if (newCart) {
          updateCart(newCart);
          setSideCartState(true);
          const items =
            (data.customerActions?.cart?.addSimpleItem
              ?.items as CartSimpleItem[]) || [];
          const item = items.length
            ? (items[items.length - 1] as CartSimpleItem)
            : null;

          fireAddToCartEvent(item, items!, cart.customer?.id);
        }
        if (user) {
          if (
            user?.phone !== newCart?.contactInfo?.phone ||
            user?.email !== newCart?.contactInfo?.email
          )
            await updateContactInfo(
              {
                info: {
                  name: user?.name!,
                  email: user?.email,
                  phone: user?.phone,
                },
              },
              newCart?.id
            );
        }
        if (onCompleted) onCompleted(data);
      },
      onError: (error) => {
        if (error.message === CheckoutServiceErrorCodes.NotFound) {
          updateCart(defaultValue);
          router.push("/");
        }
        if (error.message === CheckoutServiceErrorCodes.CartIsReserved)
          setCartPaymentPopUpState(true);
        setCartLoading(false);
        setSideCartState(false);
      },
    });
    setCartLoading(false);
  };

  const replaceCartItemsWithSimpleItem = async (
    variables: Omit<ReplaceCartItemsWithSimpleItemMutationVariables, "storeId">,
    onCompleted?: (data: ReplaceCartItemsWithSimpleItemMutation) => void
  ) => {
    setCartLoading(true);
    const newSessionId = sessionIdCheckAndGenerate();
    await replaceCartWithSimpleItemMutation({
      variables: {
        ...variables,
        storeId,
      },
      context: {
        headers: {
          sessionId: newSessionId ?? sessionId,
        },
      },
      onCompleted: (data) => {
        if (data?.customerActions?.cart?.replaceCartItemsWithSimpleItem) {
          updateCart(
            data?.customerActions?.cart?.replaceCartItemsWithSimpleItem
          );
          push(
            `/${locale.split("-")[0]}/checkout/${
              cart.recoveryCart != null
                ? query?.cartId
                : data?.customerActions?.cart?.replaceCartItemsWithSimpleItem
                    ?.id
            }`
          );
        }
        if (onCompleted) onCompleted(data);
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
    setCartLoading(false);
  };

  const replaceCartItemsWithCustomItem = async (
    variables: Omit<ReplaceCartItemsWithCustomItemMutationVariables, "storeId">,
    onCompleted?: (data: ReplaceCartItemsWithCustomItemMutation) => void
  ) => {
    setCartLoading(true);
    const newSessionId = sessionIdCheckAndGenerate();
    await replaceCartWithCustomItemMutation({
      variables: {
        ...variables,
        storeId,
      },
      context: {
        headers: {
          sessionId: newSessionId ?? sessionId,
        },
      },
      onCompleted: (data) => {
        if (data?.customerActions?.cart?.replaceCartItemsWithCustomItem) {
          updateCart(
            data?.customerActions?.cart?.replaceCartItemsWithCustomItem
          );
          push(
            `/${locale.split("-")[0]}/checkout/${
              cart.recoveryCart != null
                ? query?.cartId
                : data?.customerActions?.cart?.replaceCartItemsWithCustomItem
                    ?.id
            }`
          );
        }
        if (onCompleted) onCompleted(data);
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
    setCartLoading(false);
  };

  const addCustomItem = async (
    variables: Omit<AddCustomItemMutationVariables, "storeId">,
    onCompleted?: (data: AddCustomItemMutation) => void
  ) => {
    setCartLoading(true);
    const generatedSessionId = sessionIdCheckAndGenerate();
    if (generatedSessionId) {
      context.headers.sessionId = generatedSessionId;
    }
    await addCustomItemMutation({
      variables: {
        ...variables,
        storeId,
      },
      context,
      onCompleted: async (data) => {
        const newCart = data?.customerActions?.cart?.addCustomItem;
        if (newCart) {
          updateCart(newCart);
          setSideCartState(true);
          const items =
            (data.customerActions?.cart?.addCustomItem
              ?.items as CartCustomItem[]) || [];
          const item = items.length
            ? (items[items.length - 1] as CartCustomItem)
            : null;
          fireAddToCartEvent(item, items!, cart.customer?.id);
        }
        if (user) {
          if (
            user?.phone !== newCart?.contactInfo?.phone ||
            user?.email !== newCart?.contactInfo?.email
          )
            await updateContactInfo(
              {
                info: {
                  name: user?.name!,
                  email: user?.email,
                  phone: user?.phone,
                },
              },
              newCart?.id
            );
        }
        if (onCompleted) onCompleted(data);
      },
      onError: (error) => {
        if (error.message === CheckoutServiceErrorCodes.NotFound) {
          updateCart(defaultValue);
          router.push("/");
        }
        if (error.message === CheckoutServiceErrorCodes.CartIsReserved)
          setCartPaymentPopUpState(true);
        setSideCartState(false);
        setCartLoading(false);
      },
    });
    setCartLoading(false);
  };

  const updateCustomItem = async (
    variables: {
      item: {
        productId: string;
        quantity: number;
        selectedVariants?: {
          categoryId: string;
          variantId: string;
          quantity: number;
        }[];
      };
    },

    onCompleted?: (data: UpdateCustomItemMutation) => void
  ) => {
    const item = getCartItem(cart, variables.item.productId) as CartCustomItem;
    if (!item) {
      await addCustomItem({
        item: {
          productId: variables?.item?.productId,
          quantity: variables?.item?.quantity,
          selectedVariants: variables?.item?.selectedVariants!,
        },
      });
      return;
    }
    setCartLoading(true);
    const selectedVariants =
      variables?.item?.selectedVariants || getSelectedVariants(item);
    await updateCustomItemMutation({
      variables: {
        item: {
          productId: variables?.item?.productId,
          quantity: variables?.item?.quantity,
          itemId: item?.id,
          selectedVariants,
        },
        storeId,
      },
      context,
      onCompleted: (data) => {
        if (data?.customerActions?.cart?.updateCustomItem) {
          updateCart(data?.customerActions?.cart?.updateCustomItem);
          const items =
            (data.customerActions?.cart?.updateCustomItem
              ?.items as CartCustomItem[]) || [];
          const item = items.find(
            (item) => item.product?.id === variables.item.productId
          ) as CartCustomItem;
          fireAddToCartEvent(item, items!, cart.customer?.id);
        }
        if (onCompleted) onCompleted(data);
      },
      onError: (error) => {
        handelErrors(error);
        setCartLoading(false);
        setSideCartState(false);
      },
    });
    setCartLoading(false);
  };

  const removeCustomItem = async (
    variables: {
      item: {
        itemId: string;
        productId: string;
      };
    },
    onCompleted?: (data: UpdateCustomItemMutation) => void
  ) => {
    const item = getCartItem(
      cart,
      variables?.item?.productId,
      variables?.item?.itemId
    ) as CartCustomItem;
    setCartLoading(true);

    await updateCustomItemMutation({
      variables: {
        item: {
          productId: variables.item.productId,
          quantity: 0, // zero means remove the item
          itemId: variables?.item?.itemId,
          selectedVariants: getSelectedVariants(item),
        },
        storeId,
      },
      context,
      onCompleted: (data) => {
        if (data?.customerActions?.cart?.updateCustomItem) {
          updateCart(data?.customerActions?.cart?.updateCustomItem);
        }
        if (onCompleted) onCompleted(data);
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
    setCartLoading(false);
  };
  const setCheckoutCart = async () => {
    await setCheckoutCartMutation({
      variables: {
        cartId:
          cartId === "recoverycart_"
            ? "RecoveryCart_" + query.cartId?.slice(underscoreIndex + 1)
            : cart?.id,

        storeId: storeId,
      },
      context,
      onCompleted: (data) => {
        if (
          data?.customerActions?.cart?.checkoutCart &&
          recoverySessionId == undefined
        ) {
          updateCart(data?.customerActions?.cart?.checkoutCart);
          router.push(
            `/checkout/payment/${
              cart.recoveryCart != null ? query?.cartId : cart.id
            }`
          );
        }
        if (
          data?.customerActions?.cart?.checkoutCart &&
          recoverySessionId !== undefined
        ) {
          updateRecoveryCart(data?.customerActions?.cart?.checkoutCart);
          router.push(
            `/checkout/payment/${
              cart.recoveryCart != null ? query?.cartId : cart.id
            }`
          );
        }
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
  };

  const setCartRefCode = async (
    variables: Omit<SetCartRefCodeMutationVariables, "storeId" | "cartId">
  ) => {
    await setCartRefCodeMutation({
      variables: {
        ...variables,
        cartId: cart?.id,
        storeId,
      },
      context,
      onCompleted: (data) => {
        if (data?.customerActions?.cart?.setCartRefCode) {
          updateCart(data?.customerActions?.cart?.setCartRefCode);
        }
      },
      onError: (error) => {
        handelErrors(error);
        setSideCartState(false);
      },
    });
  };

  return {
    cart,
    updateCart,
    updateRecoveryCart,
    resetRecoveryCart,
    setSideCartState,
    setCartPaymentPopUpState,
    setCartEmptyPopUpState,
    setShippingInformation,
    addSimpleItem: {
      addSimpleItem,
      loading: addSimpleItemLoading,
      error: addSimpleItemError,
    },
    updateSimpleItem: {
      updateSimpleItem,
      loading: updateSimpleItemLoading,
      error: updateSimpleItemError,
    },
    removeSimpleItem: {
      removeSimpleItem,
      loading: updateSimpleItemLoading,
      error: updateSimpleItemError,
    },
    addCustomItem: {
      addCustomItem,
      loading: addCustomItemLoading,
      error: addCustomItemError,
    },
    replaceCartItemsWithCustomItem: {
      replaceCartItemsWithCustomItem,
      loading: replaceCartWithCustomItemLoading,
      error: replaceCartWithCustomItemError,
    },
    replaceCartItemsWithSimpleItem: {
      replaceCartItemsWithSimpleItem,
      loading: replaceCartWithSimpleItemLoading,
      error: replaceCartWithSimpleItemError,
    },
    updateCustomItem: {
      updateCustomItem,
      loading: updateCustomItemLoading,
      error: updateCustomItemError,
    },
    removeCustomItem: {
      removeCustomItem,
      loading: updateCustomItemLoading,
      error: updateCustomItemError,
    },
    updateContactInfo: {
      updateContactInfo,
      loading: UpdateContactInfoLoading,
      error: UpdateContactInfoError,
    },
    setCartShippingDetails: {
      setCartShippingDetails,
      loading: useSetCartShippingDetailsLoading,
      error: useSetCartShippingDetailsError,
    },
    setCartShippingRate: {
      setCartShippingRate,
      loading: setCartShippingRateLoading,
      error: setCartShippingRateError,
    },
    applyPromoCode: {
      applyPromoCode,
      loading: applyPromoCodeLoading,
      error: applyPromoCodeError,
    },
    removePromoCode: {
      removePromoCode,
      loading: removePromoCodeLoading,
      error: removePromoCodeError,
    },
    setCartRefCode: {
      setCartRefCode,
      loading: settingCartRefCode,
      error: settingCartRefCodeError,
    },
    setCheckoutCart: {
      setCheckoutCart,
      loading: setCheckoutCartLoading,
      error: setCheckoutCartError,
    },
  };
}
